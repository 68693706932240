import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import moment from "moment";
import React from "react";
import { Controller } from "react-hook-form";
import { useAppSelector } from "../../hooks";
import { useGetCargoDimensionTypesQuery } from "../../services/cargoDimensionTypes";
import { useGetCountriesQuery } from "../../services/countries";
import { useGetMeasurementsQuery } from "../../services/measurements";
import { useGetStatesQuery } from "../../services/states";

interface ContainerProps {
  control: any;
  errors: any;
}

const TransportationContainer: React.FC<ContainerProps> = ({
  control,
  errors,
}) => {
  const minDate = moment().add(3, "days").format("YYYY-MM-DD");
  const maxDate = moment().add(6, "months").format("YYYY-MM-DD");
  const {data: countries} = useGetCountriesQuery();
  const {data: states} = useGetStatesQuery();
  const {data: measurements} = useGetMeasurementsQuery();
  const {data: dimensionTypes} = useGetCargoDimensionTypesQuery();

  return (
    <>
      <IonItem>
        <IonLabel>Transportation Details</IonLabel>
      </IonItem>
      <IonList>
        <IonItem>
          <IonLabel position="stacked">
            Origin Country<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.originCountryName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {countries?.map((country) => {
                  return (
                    <IonSelectOption key={country.id} value={country.name}>
                      {country.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.originCountryName && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.originCountryName.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Origin State<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.originProvinceStateName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {states?.map((state) => {
                  return (
                    <IonSelectOption key={state.id} value={state.name}>
                      {state.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.originProvinceStateName && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.originProvinceStateName.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Origin Place<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.originPlace"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
          {errors.landTransportServiceDTO?.originPlace && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.originPlace.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Destination Country<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.destinationCountryName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {countries?.map((country) => {
                  return (
                    <IonSelectOption key={country.id} value={country.name}>
                      {country.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.destinationCountryName && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.destinationCountryName.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Destination State<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.destinationProvinceStateName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {states?.map((state) => {
                  return (
                    <IonSelectOption key={state.id} value={state.name}>
                      {state.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.destinationProvinceStateName && (
            <IonText color="danger">
              <small>
                {
                  errors.landTransportServiceDTO.destinationProvinceStateName
                    .message
                }
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Destination Place<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.destinationPlace"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
          {errors.landTransportServiceDTO?.destinationPlace && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.destinationPlace.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Commodity Description<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.commodityDescription"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
          {errors.landTransportServiceDTO?.commodityDescription && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.commodityDescription.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Total Weight (KG/MT)<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.totalWeight"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
          {errors.landTransportServiceDTO?.totalWeight && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.totalWeight.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Measurement Name<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.measurementName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {measurements?.map((measurement) => {
                  return (
                    <IonSelectOption
                      key={measurement.id}
                      value={measurement.name}
                    >
                      {measurement.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.measurementName && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.measurementName.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Cargo Dimension Type<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.cargoDimensionTypeName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {dimensionTypes?.map((dimension) => {
                  return (
                    <IonSelectOption key={dimension.id} value={dimension.name}>
                      {dimension.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.landTransportServiceDTO?.cargoDimensionTypeName && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.cargoDimensionTypeName.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Shipment Date<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.shipmentDate"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonDatetime
                displayFormat="DD MMM YYYY"
                min={minDate}
                max={maxDate}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              ></IonDatetime>
            )}
          />
          {errors.landTransportServiceDTO?.shipmentDate && (
            <IonText color="danger">
              <small>
                {errors.landTransportServiceDTO.shipmentDate.message}
              </small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Scope of Service/Conditions
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.incoTermsRemarks"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Packing list remarks
          </IonLabel>
          <Controller
            name="landTransportServiceDTO.packingListRemarks"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
      </IonList>
    </>
  );
};

export default TransportationContainer;
