import { emptySplitApi } from '.'
import { Country } from '../types'

export const countryApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query<Country[], void>({
      query: () => 'countries',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Country' as const, id })),
              { type: 'Country', id: 'LIST' },
            ]
          : [{ type: 'Country', id: 'LIST' }],
    }),
    addCountry: build.mutation<Country, Partial<Country>>({
      query: (body) => ({
        url: `countries`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Country', id: 'LIST' }],
    }),
    getCountry: build.query<Country, string>({
      query: (id) => `countries/${id}`,
      providesTags: (result, error, id) => [{ type: 'Country', id }],
    }),
    updateCountry: build.mutation<void, Pick<Country, 'id'> & Partial<Country>>({
      query: ({ id, ...patch }) => ({
        url: `countries/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Country', id }],
    }),
    deleteCountry: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `countries/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Country', id }],
    }),
  }),
})

export const {
  useGetCountryQuery,
  useGetCountriesQuery,
  useAddCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countryApi
