import {
  IonAlert,
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { add, createOutline, trashBinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useDeleteCargoTypeMutation, useGetCargoTypesQuery } from "../../services/cargoTypes";
import { CargoType } from "../../types";
import ManageCargoType from "./ManageCargoType";

const CargoTypeContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const {data: cargoTypes} = useGetCargoTypesQuery();
  const [deleteCargoType] = useDeleteCargoTypeMutation();
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selected, setSelected] = useState<CargoType>(
    {} as CargoType
  );

  const onAddClicked = () => {
    setShowEdit(false);
    setShowAdd(true);
  };

  const onDeleteClicked = (item: CargoType) => {
    setSelected(item);
    setShowAlert(true);
    setShowEdit(false);
  };

  const onEditClicked = (item: CargoType) => {
    setSelected(item);
    setShowAdd(false);
    setShowEdit(true);
  };

  const onDelete = async (id: number) => {
    try {
      await deleteCargoType(id).unwrap();
    } catch (err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  }

  return (
    <>
      {showAdd && (
        <ManageCargoType setClose={setShowAdd} />
      )}
      {showEdit && (
        <ManageCargoType
          cargoType={selected}
          setClose={setShowEdit}
        />
      )}
      <IonGrid className="ion-padding">
        <IonRow  className='header-row ion-padding-bottom ion-padding-top'>
          <IonCol size="4">
            <IonLabel>Name</IonLabel>
          </IonCol>
          <IonCol size="4">
            <IonLabel>Description</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Edit</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Delete</IonLabel>
          </IonCol>
        </IonRow>
        {cargoTypes?.map((item) => (
          <IonRow key={item.id}>
            <IonCol size="4" className='ion-padding-vertical'>
              <IonLabel>{item.name}</IonLabel>
            </IonCol>
            <IonCol size="4" className='ion-padding-vertical'>
              <IonLabel>{item.description}</IonLabel>
            </IonCol>
            <IonCol size="2" className='ion-text-center'>
              <IonButton fill="clear" onClick={() => onEditClicked(item)}>
                <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
              </IonButton>
            </IonCol>
            <IonCol size="2" className='ion-text-center'>
              <IonButton fill="clear" onClick={() => onDeleteClicked(item)}>
                <IonIcon
                  color="danger"
                  slot="icon-only"
                  icon={trashBinOutline}
                ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => onAddClicked()}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        subHeader={"Delete Cargo Type"}
        message={"Confirm delete?"}
        buttons={[
          {
            text: "Ok",
            handler: () => onDelete(selected.id),
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      />
    </>
  );
};
export default CargoTypeContainer;
