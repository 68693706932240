import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonText,
} from "@ionic/react";
import React from "react";
import {
  Freight,
  FreightForwardRequest,
  MultiModeRequest,
  RequirementType,
  Transport,
  TransportRequest,
} from "../../types";

interface ContainerProps {
  requirementType: RequirementType;
  requirement: FreightForwardRequest | TransportRequest | MultiModeRequest;
}

const ShipperRequirementsContainer: React.FC<ContainerProps> = ({
  requirementType,
  requirement,
}) => {
  const reqView = (item: Transport | Freight) => {
    return (
      <>
        <IonCardHeader>
          <IonCardTitle>
            {`To ${item.destinationPlace},
                ${item.destinationCountryName},
                ${item.destinationProvinceStateName}`}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonNote slot="start" color="primary">
                Description
              </IonNote>
              <IonLabel>
                <IonText>{item.commodityDescription}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Shipment Date
              </IonNote>
              <IonLabel>
                <IonText>{item.shipmentDate}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Origin
              </IonNote>
              <IonLabel>
                <IonText>
                  {`${item.originPlace},
                  ${item.originCountryName},
                  ${item.originProvinceStateName}`}
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Scope
              </IonNote>
              <IonLabel>
                <IonText>{item.incoTermsRemarks}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                {item.totalWeight}
              </IonNote>
              <IonLabel>
                <IonText>{item.measurementName}</IonText>
              </IonLabel>
            </IonItem>
            {(item as Freight).cargoTypeName && (
              <IonItem>
                <IonNote slot="start" color="primary">
                  Cargo Type
                </IonNote>
                <IonLabel>
                  <IonText>{(item as Freight).cargoTypeName}</IonText>
                </IonLabel>
              </IonItem>
            )}
            <IonItem>
              <IonNote slot="start" color="primary">
                Cargo Dimension Type
              </IonNote>
              <IonLabel>
                <IonText>{item.cargoDimensionTypeName}</IonText>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonCardContent>
      </>
    );
  };
  return (
    <IonCard color='secondary'>
      <IonItem color='warning'>Quote due: {requirement.quoteDueDate}</IonItem>
      {requirementType === RequirementType.MultiMode ? (
        <>
          {reqView((requirement as MultiModeRequest).landTransportServiceDTO)}
          {reqView((requirement as MultiModeRequest).freightForwardServiceDTO)}
        </>
      ) : requirementType === RequirementType.Transport ? (
        reqView((requirement as TransportRequest).landTransportServiceDTO)
      ) : (
        reqView((requirement as FreightForwardRequest).freightForwardServiceDTO)
      )}
    </IonCard>
  );
};

export default ShipperRequirementsContainer;
