import {
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
  } from "@ionic/react";
import React, { useState } from "react";
import {LOGISTICS_ALLIED_SERVICES} from "../../constants";
import AlliedServicesContainer from "../shipper/AlliedServicesContainer";


  const ChooseLogisticsAlliedService: React.FC = () => {
    const [service, setService] = useState("");

    return (
      <>
        <IonItem>
          <IonLabel>Choose Logistics Allied Service</IonLabel>
        </IonItem>

        <IonList className="ion-padding">
          <IonRadioGroup
            value={service}
            onIonChange={(e) => setService(e.detail.value)}
          >
            {LOGISTICS_ALLIED_SERVICES.map((item) => (
              <IonItem key={item} lines="none">
                <IonLabel className="ion-text-wrap">{item}</IonLabel>
                <IonRadio mode="md" slot="start" value={item} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
        {service && <AlliedServicesContainer service={service}/>}
        <IonItem>
          <IonLabel>&nbsp;</IonLabel>
        </IonItem>
      </>
    );
  };

  export default ChooseLogisticsAlliedService;
