import { emptySplitApi } from '.'
import { ServiceRequestStatus } from '../types'

export const serviceRequestStatusApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceRequestStatuss: build.query<ServiceRequestStatus[], void>({
      query: () => 'servicerequeststatus',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ServiceRequestStatus' as const, id })),
              { type: 'ServiceRequestStatus', id: 'LIST' },
            ]
          : [{ type: 'ServiceRequestStatus', id: 'LIST' }],
    }),
    addServiceRequestStatus: build.mutation<ServiceRequestStatus, Partial<ServiceRequestStatus>>({
      query: (body) => ({
        url: `servicerequeststatus`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ServiceRequestStatus', id: 'LIST' }],
    }),
    getServiceRequestStatus: build.query<ServiceRequestStatus, string>({
      query: (id) => `servicerequeststatus/${id}`,
      providesTags: (result, error, id) => [{ type: 'ServiceRequestStatus', id }],
    }),
    updateServiceRequestStatus: build.mutation<void, Pick<ServiceRequestStatus, 'id'> & Partial<ServiceRequestStatus>>({
      query: ({ id, ...patch }) => ({
        url: `servicerequeststatus/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ServiceRequestStatus', id }],
    }),
    deleteServiceRequestStatus: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `servicerequeststatus/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'ServiceRequestStatus', id }],
    }),
  }),
})

export const {
  useGetServiceRequestStatusQuery,
  useGetServiceRequestStatussQuery,
  useAddServiceRequestStatusMutation,
  useUpdateServiceRequestStatusMutation,
  useDeleteServiceRequestStatusMutation,
} = serviceRequestStatusApi
