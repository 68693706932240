import React from 'react';
import { useAppSelector } from '../hooks';
import { IonToast } from '@ionic/react';
import { AlertMessage } from '../types';

const Alert: React.FC = () => {

  const alerts = useAppSelector(state => state.alert.alerts);
  return (
    <>
      {alerts &&
        alerts.length > 0 &&
        alerts.map((alert: AlertMessage) => (<IonToast key={alert.id} isOpen message={alert.msg} color={alert.alertType} duration={2000} />))}
    </>
  )
}

export default Alert;
