import { emptySplitApi } from '.'
import { CargoType } from '../types'

export const cargoTypeApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getCargoTypes: build.query<CargoType[], void>({
      query: () => 'cargotypes',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CargoType' as const, id })),
              { type: 'CargoType', id: 'LIST' },
            ]
          : [{ type: 'CargoType', id: 'LIST' }],
    }),
    addCargoType: build.mutation<CargoType, Partial<CargoType>>({
      query: (body) => ({
        url: `cargotypes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CargoType', id: 'LIST' }],
    }),
    getCargoType: build.query<CargoType, string>({
      query: (id) => `cargotypes/${id}`,
      providesTags: (result, error, id) => [{ type: 'CargoType', id }],
    }),
    updateCargoType: build.mutation<void, Pick<CargoType, 'id'> & Partial<CargoType>>({
      query: ({ id, ...patch }) => ({
        url: `cargotypes/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CargoType', id }],
    }),
    deleteCargoType: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `cargotypes/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'CargoType', id }],
    }),
  }),
})

export const {
  useGetCargoTypeQuery,
  useGetCargoTypesQuery,
  useAddCargoTypeMutation,
  useUpdateCargoTypeMutation,
  useDeleteCargoTypeMutation,
} = cargoTypeApi
