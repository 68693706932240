import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_ENDPOINT } from '../constants'
import { LoginForm, LoginResponse } from '../types'

export const authApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: `${API_ENDPOINT}/`}),
  reducerPath: 'authApi',
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginForm>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { useLoginMutation } = authApi
