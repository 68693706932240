import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddCountryMutation, useUpdateCountryMutation } from "../../services/countries";
import { Country } from "../../types";
interface ContainerProps {
  setClose?: any;
  country?: Country;
}
const ManageCountries: React.FC<ContainerProps> = ({ country, setClose }) => {
  const dispatch = useAppDispatch();
  const [addCountry] = useAddCountryMutation();
  const [updateCountry] = useUpdateCountryMutation();
  const { control, reset, handleSubmit } = useForm<Country>();
  const initialValues = {
    id: 0,
    name: "",
    code:"",
    isoCode:""
  };

  useEffect(() => {
    reset(country);
  }, [country]);

  const onManagecountry: SubmitHandler<Country> = async (data) => {
    try {
      if (country) {
        await updateCountry(data).unwrap();
      } else {
        await addCountry(data).unwrap();
        reset(initialValues);
      }
      setClose(false);
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManagecountry)}>
      <IonList>
        <IonItem className="ion-padding">
          <IonLabel>{country ? "Edit" : "Add"} country</IonLabel>
          <IonButton
            slot="end"
            className="ion-no-border"
            onClick={() => setClose(false)}
            color="danger"
          >
            <IonIcon
              slot="start"
              size="small"
              icon={closeCircleSharp}
            ></IonIcon>
            close
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Name<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Code</IonLabel>
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">ISO Code<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="isoCode"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item,) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>

        <IonButton expand="block" type="submit">
          {country ? "Update" : "Add"}
        </IonButton>
      </IonList>
    </form>
  );
};
export default ManageCountries;
