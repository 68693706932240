import { emptySplitApi } from '.'
import { State } from '../types'

export const stateApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getStates: build.query<State[], void>({
      query: () => 'provinces',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'State' as const, id })),
              { type: 'State', id: 'LIST' },
            ]
          : [{ type: 'State', id: 'LIST' }],
    }),
    addState: build.mutation<State, Partial<State>>({
      query: (body) => ({
        url: `provinces`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'State', id: 'LIST' }],
    }),
    getState: build.query<State, string>({
      query: (id) => `provinces/${id}`,
      providesTags: (result, error, id) => [{ type: 'State', id }],
    }),
    updateState: build.mutation<void, Pick<State, 'id'> & Partial<State>>({
      query: ({ id, ...patch }) => ({
        url: `provinces/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'State', id }],
    }),
    deleteState: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `provinces/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'State', id }],
    }),
  }),
})

export const {
  useGetStateQuery,
  useGetStatesQuery,
  useAddStateMutation,
  useUpdateStateMutation,
  useDeleteStateMutation,
} = stateApi
