import { emptySplitApi } from '.'
import { ServiceQuoteStatus } from '../types'

export const serviceQuoteStatusApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceQuoteStatuss: build.query<ServiceQuoteStatus[], void>({
      query: () => 'servicequotestatus',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ServiceQuoteStatus' as const, id })),
              { type: 'ServiceQuoteStatus', id: 'LIST' },
            ]
          : [{ type: 'ServiceQuoteStatus', id: 'LIST' }],
    }),
    addServiceQuoteStatus: build.mutation<ServiceQuoteStatus, Partial<ServiceQuoteStatus>>({
      query: (body) => ({
        url: `servicequotestatus`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ServiceQuoteStatus', id: 'LIST' }],
    }),
    getServiceQuoteStatus: build.query<ServiceQuoteStatus, string>({
      query: (id) => `servicequotestatus/${id}`,
      providesTags: (result, error, id) => [{ type: 'ServiceQuoteStatus', id }],
    }),
    updateServiceQuoteStatus: build.mutation<void, Pick<ServiceQuoteStatus, 'id'> & Partial<ServiceQuoteStatus>>({
      query: ({ id, ...patch }) => ({
        url: `servicequotestatus/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ServiceQuoteStatus', id }],
    }),
    deleteServiceQuoteStatus: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `servicequotestatus/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'ServiceQuoteStatus', id }],
    }),
  }),
})

export const {
  useGetServiceQuoteStatusQuery,
  useGetServiceQuoteStatussQuery,
  useAddServiceQuoteStatusMutation,
  useUpdateServiceQuoteStatusMutation,
  useDeleteServiceQuoteStatusMutation,
} = serviceQuoteStatusApi
