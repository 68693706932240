import { IonAlert, IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import "./ExploreContainer.css";
import { airplaneOutline, carOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import React, { useState } from "react";

const ExploreContainer: React.FC = () => {
  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);

  return (
    <div className="container">
      <div>
        <strong>You are a</strong>
      </div>
      <div className="message">
        <div className="box" onClick={() => history.push("/shipper")}>
          Business Provider
          <IonButton fill="clear">
            <IonIcon icon={airplaneOutline} slot="end"></IonIcon>
          </IonButton>
        </div>
        <div className="box" onClick={() => history.push("/fleet")}>
          Service Provider
          <IonButton fill="clear">
            <IonIcon icon={carOutline} slot="end"></IonIcon>
          </IonButton>
        </div>
      </div>
      <IonItem className="ion-padding">
        <IonLabel>Not a provider?</IonLabel>
        <IonButton onClick={() => setShowAlert(true)}>Agent</IonButton>
        <IonButton onClick={() => history.push("/login")}>Admin</IonButton>
      </IonItem>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        cssClass="my-custom-class"
        subHeader={"Agent Options"}
        message={"What do you want to do?"}
        buttons={[
          {
            text: "Register",
            handler: () => {
              history.push("/agent/registration");
            },
          },
          {
            text: "Login",
            handler: () => {
              history.push("/login");
            },
          },
        ]}
      />
    </div>
  );
};

export default ExploreContainer;
