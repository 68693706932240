import { IonRouterOutlet } from "@ionic/react";
import React, { useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router";

import Home from "../pages/Home";
import Shipper from "../pages/shipper/Shipper";
import ShipperRegister from "../pages/shipper/ShipperRegister";
import AgentRegister from "../pages/agent/AgentRegister";
import FleetRegister from "../pages/fleet/FleetRegister";
import Fleet from "../pages/fleet/Fleet";
import Login from "../pages/Login";
import ShipperPostLogin from "../pages/shipper/ShipperPostLogin";
import FleetPostLogin from "../pages/fleet/FleetPostLogin";
import NewRequirement from "../pages/shipper/NewRequirement";
import NewRequirementFleet from "../pages/fleet/NewRequirement";
import ShipperDashboard from "../pages/shipper/ShipperDashboard";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";
import ShipperRequirements from "../pages/shipper/ShipperRequirements";
import PrivateRoute from "./PrivateRoute";
import AdminDashboard from "../pages/admin/AdminDashboard";
import Providers from "../pages/admin/Providers";
import BusinessCategories from "../pages/admin/BusinessCategories";
import Measurements from "../pages/admin/Measurements";
import Countries from "../pages/admin/Countries";
import States from "../pages/admin/States";
import ServiceCategories from "../pages/admin/ServiceCategories";
import ServiceRegions from "../pages/admin/ServiceRegions";
import CargoTypes from "../pages/admin/CargoTypes";
import CargoDimensionTypes from "../pages/admin/CargoDimensionTypes";
import ServiceQuoteStatusTypes from "../pages/admin/ServiceQuoteStatusTypes";
import ServiceRequestStatusTypes from "../pages/admin/ServiceRequestStatusTypes";
import { useAppDispatch, useAppSelector } from "../hooks";
import { getProfileAsync, performLogout, tokenValidated } from "../redux/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayAlert } from "../redux/alertSlice";
import AgentPostLogin from "../pages/agent/AgentPostLogin";

const AppRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(state => state.auth.loginResponse);
  const tokenValid = useAppSelector(state => state.auth.tokenValid);
  const history = useHistory();

  useEffect(() => {
    const validateToken = async () => {
      try {
        unwrapResult(await dispatch(getProfileAsync(userDetails.token)));
        dispatch(displayAlert(`Welcome back ${userDetails.user.name}`, 'success'));
        dispatch(tokenValidated());
      } catch (e) {
        dispatch((performLogout()));
        dispatch(displayAlert('Please login again', 'danger'));
        history.push("/home");
      }
    }
    if (userDetails.token) {
      validateToken();
    } else {
      history.push("/home");
    }
  }, [userDetails.token]);

  return (
    <IonRouterOutlet id="main">
      <Route exact path="/home">
        <Home />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route exact path="/shipper">
        <Shipper />
      </Route>
      <Route exact path="/fleet">
        <Fleet />
      </Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <PrivateRoute exact path="/shipper/welcome">
        <ShipperPostLogin />
      </PrivateRoute>
      <PrivateRoute exact path="/fleet/welcome">
        <FleetPostLogin />
      </PrivateRoute>
      <PrivateRoute exact path="/agent/welcome">
        <AgentPostLogin />
      </PrivateRoute>
      <PrivateRoute exact path="/shipper/new">
        <NewRequirement />
      </PrivateRoute>
      <PrivateRoute exact path="/fleet/new">
        <NewRequirementFleet />
      </PrivateRoute>

      <Route exact path="/shipper/registration">
        <ShipperRegister />
      </Route>
      <Route exact path="/fleet/registration">
        <FleetRegister />
      </Route>
      <Route exact path="/agent/registration">
        <AgentRegister />
      </Route>

      <PrivateRoute exact path="/shipper/dashboard">
        <ShipperDashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/shipper/requirements">
        <ShipperRequirements />
      </PrivateRoute>
      <PrivateRoute exact path="/shipper/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path="/shipper/profile/edit">
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path="/shippper/profile/password">
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute exact path="/fleet/profile">
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path="/fleet/profile/edit">
        <Profile />
      </PrivateRoute>
      <PrivateRoute exact path="/fleet/profile/password">
        <ChangePassword />
      </PrivateRoute>

      <PrivateRoute exact path="/admin/welcome">
        <AdminDashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/businessProviders">
        <Providers />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/serviceProviders">
        <Providers />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/businessCategories">
        <BusinessCategories />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/businessCategories/add">
        <BusinessCategories />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/businessCategories/edit/:id">
        <BusinessCategories />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/measurements">
        <Measurements />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/countries">
        <Countries />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/states">
        <States />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/agents">
        <Providers />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/serviceCategories">
        <ServiceCategories />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/serviceregions">
        <ServiceRegions />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/cargotypes">
        <CargoTypes />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/cargodimensiontypes">
        <CargoDimensionTypes />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/servicequotestatustypes">
        <ServiceQuoteStatusTypes />
      </PrivateRoute>
      <PrivateRoute exact path="/admin/servicerequeststatustypes">
        <ServiceRequestStatusTypes />
      </PrivateRoute>
    </IonRouterOutlet>
  );
};

export default AppRoute;
