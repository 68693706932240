import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import ChangePasswordContainer from "../components/ChangePasswordContainer";

const ChangePassword: React.FC = () => {
  const history = useHistory();

  const onSubmit = () => {
    history.push("/shipper/welcome");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Change Password</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ChangePasswordContainer />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButton expand="block" onClick={() => onSubmit()}>
          Update
        </IonButton>
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default ChangePassword;
