import { IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";
import { useGetCountriesQuery } from "../services/countries";
import { useGetStatesQuery } from "../services/states";

interface ContainerProps {
  control: any,
  errors: any,
  edit?: boolean
}

const BaseRegisterContainer: React.FC<ContainerProps> = ({control, errors, edit}) => {
  const {data: states, isLoading: isStateLoading} = useGetStatesQuery();
  const {data: countries, isLoading: isCountryLoading} = useGetCountriesQuery();
  const userInput = (onChange: any, onBlur: any, value: any) => (
    <IonInput
      onBlur={onBlur}
      onIonChange={(input) => onChange(input)}
      value={value}
    />
  )

  if (isCountryLoading || isStateLoading) return <div>Loading...</div>

  return (
    <>
      <IonItem>
        <IonLabel position="stacked">
          Company Name<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="companyName"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
        {errors.companyName && (
          <IonText color="danger">
            <small>{errors.companyName.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          Contact Person Name<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="contactPersonName"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
        {errors.contactPersonName && (
          <IonText color="danger">
            <small>{errors.contactPersonName.message}</small>
          </IonText>
        )}
      </IonItem>
      {edit && (
        <IonItem>
          <IonLabel position="stacked">Designation</IonLabel>
          <Controller
            name="contactPersonDesignation"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
          />
        </IonItem>
      )}
      <IonItem>
        <IonLabel position="stacked">
          Country<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="countryName"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonSelect
              value={value}
              onBlur={onBlur}
              onIonChange={(input) => onChange(input)}
            >
              {countries?.map((country) => {
                return (
                  <IonSelectOption key={country.id} value={country.name}>
                    {country.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
        />
        {errors.countryName && (
          <IonText color="danger">
            <small>{errors.countryName.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          State<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="provinceStateName"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonSelect
              value={value}
              onBlur={onBlur}
              onIonChange={(input) => onChange(input)}
            >
              {states?.map((state) => {
                return (
                  <IonSelectOption key={state.id} value={state.name}>
                    {state.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
        />
        {errors.provinceStateName && (
          <IonText color="danger">
            <small>{errors.provinceStateName.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">City/Town</IonLabel>
        <Controller
          name="city"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Postal Code</IonLabel>
        <Controller
          name="postalCode"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
        {errors.postalCode && (
          <IonText color="danger">
            <small>{errors.postalCode.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          Email<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonInput
              onBlur={onBlur}
              onIonChange={(input) => onChange(input)}
              disabled={edit}
              value={value}
            />
          )}
        />
        {errors.email && (
          <IonText color="danger">
            <small>{errors.email.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          Mobile<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="mobileNumber"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
        {errors.mobileNumber && (
          <IonText color="danger">
            <small>{errors.mobileNumber.message}</small>
          </IonText>
        )}
      </IonItem>
      {edit && (
        <>
          <IonItem>
            <IonLabel position="stacked">Office Phone Number</IonLabel>
            <Controller
              name="officePhoneNumber"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
            />
            {errors.officePhoneNumber && (
              <IonText color="danger">
                <small>{errors.officePhoneNumber.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">PAN Number</IonLabel>
            <Controller
              name="panNumber"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  disabled={edit}
                  onBlur={onBlur}
                  onIonChange={(input) => onChange(input)}
                  value={value}
                />
              )}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">GST Number</IonLabel>
            <Controller
              name="gstNumber"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
            />
            {errors.gstNumber && (
              <IonText color="danger">
                <small>{errors.gstNumber.message}</small>
              </IonText>
            )}
          </IonItem>
        </>
      )}
      <IonItem>
        <IonLabel position="stacked">Website</IonLabel>
        <Controller
          name="webSiteURL"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
        {errors.webSiteURL && (
          <IonText color="danger">
            <small>{errors.webSiteURL.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Referred by Agent Code</IonLabel>
        <Controller
          name="referredByAgentCode"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
      </IonItem>
    </>
  );
};

export default BaseRegisterContainer;
