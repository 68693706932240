import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import FleetDashboardContainer from "../../components/fleet/FleetDashboardContainer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchAllRequestsQuotes } from "../../redux/serviceRequestSlice";
import { RequirementType } from "../../types";

const FleetPostLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const loginResponse = useAppSelector(state => state.auth.loginResponse);

  useEffect(() => {
    dispatch(fetchAllRequestsQuotes(loginResponse.profile.providerCode!));
  },[]);

  const transportRequests = useAppSelector(state => state.serviceRequest.transportRequests);
  const freightForwardRequests = useAppSelector(state => state.serviceRequest.freightForwardRequests);
  const multiModeRequests = useAppSelector(state => state.serviceRequest.multiModeRequests);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Welcome Service Provider</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Welcome Service Provider</IonTitle>
          </IonToolbar>
        </IonHeader>
        {transportRequests
          .map((item) => (
            <FleetDashboardContainer
              key={item.id}
              requirementType={RequirementType.Transport}
              requirement={item}
            />
          ))}
        {freightForwardRequests
          .map((item) => (
            <FleetDashboardContainer
              key={item.id}
              requirementType={RequirementType.Freight}
              requirement={item}
            />
          ))}
        {multiModeRequests
          .map((item) => (
              <FleetDashboardContainer
                key={item.id}
                requirementType={RequirementType.MultiMode}
                requirement={item}
              />
          ))}
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default FleetPostLogin;
