import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";

interface ContainerProps {}

const FleetContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  return (
    <div className="container">
      <IonItem lines='none'>
        <IonLabel className="ion-text-wrap">
          <IonText className="description">
            RightFleet helps you to connect with Business and service their
            logistic needs.
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="ion-text-wrap">
          <IonText className="description">
            To connect with Shipper, register below
          </IonText>
        </IonLabel>
      </IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton onClick={() => history.push("/fleet/registration")}>
              Register
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={() => history.push("/login")}>Login</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default FleetContainer;
