import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { LoginResponse } from '../types';
import { setAuthToken } from '../utils/authToken';
import { getProfileApi } from './rightfleetApi';
import { authApi } from '../services/auth';

export interface AuthState {
  loginResponse: LoginResponse;
  loading: boolean;
  tokenValid: boolean;
}

const userDetails = localStorage.getItem('user_details');

const initialState: AuthState = {
  loginResponse: userDetails ? JSON.parse(userDetails) : {} as LoginResponse,
  loading: false,
  tokenValid: userDetails ? false: true,
}

export const getProfileAsync = createAsyncThunk('auth/getProfile', async (token: string) => {
  setAuthToken(token);
  const res = await getProfileApi();
  return res.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.loginResponse = {} as LoginResponse;
      state.tokenValid = true;
    },
    tokenValidated: (state) => {
      state.tokenValid = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfileAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.loading = false;
        state.loginResponse = {} as LoginResponse;
        state.tokenValid = false;
      })
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.loginResponse = payload;
          state.tokenValid = true
        }
      )
  },
});

export const {logout, tokenValidated} = authSlice.actions;

export const performLogout = (): AppThunk => (dispatch) => {
  localStorage.removeItem('user_details');
  setAuthToken('');
  dispatch(logout());
};

export default authSlice.reducer;