import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { ServiceProvider } from "../../types";
import BaseProviderCardContainer from "./BaseProviderCardContainer";

interface ContainerProps {
  provider: ServiceProvider;
}
const ServiceProviderCardContainer: React.FC<ContainerProps> = ({
  provider,
}) => {
  return (
    <IonList>
        <BaseProviderCardContainer provider={provider}/>
      <IonItem>
        <IonLabel>Service Category</IonLabel>
        <IonLabel>{provider.serviceCategoryNames}</IonLabel>
      </IonItem>
      <IonItem>
          <IonLabel>Maximum Capacity Volume</IonLabel>
          <IonLabel>{provider.maxCapacityVolume}</IonLabel>
      </IonItem>
      <IonItem>
          <IonLabel>Measurement Name</IonLabel>
          <IonLabel>{provider.measurementName}</IonLabel>
      </IonItem>
      <IonItem>
          <IonLabel>Service Region Names</IonLabel>
          <IonLabel>{provider.serviceRegionNames}</IonLabel>
      </IonItem>
    </IonList>
  );
};
export default ServiceProviderCardContainer;
