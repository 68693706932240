import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonText,
  IonTextarea,
  IonToggle,
} from "@ionic/react";
import Moment from 'react-moment';
import { calculatorOutline } from "ionicons/icons";
import React, { useState } from "react";
import {
  Freight,
  FreightForwardQuote,
  FreightForwardRequest,
  MultiModeQuote,
  MultiModeRequest,
  RequirementType,
  Transport,
  TransportQuote,
  TransportRequest,
} from "../../types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addFreightForwardServiceQuoteAsync, addLandTransportServiceQuoteAsync, addMultiModeServiceQuoteAsync } from "../../redux/serviceRequestSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayAlert } from "../../redux/alertSlice";

interface ContainerProps {
  requirementType: RequirementType,
  requirement: TransportRequest | FreightForwardRequest | MultiModeRequest;
}

const FleetDashboardContainer: React.FC<ContainerProps> = ({
  requirementType, requirement
}) => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector(state => state.auth.loginResponse);
  const [quote, setQuote] = useState(false);
  const [quoteRemarks, setQuoteRemarks] = useState("");

  const onSubmit = async () => {
    try {
      if (requirementType === RequirementType.Transport) {
        const transportQuote: TransportQuote = {
          landTransportServiceRequestId: requirement.id!,
          quoteRemarks,
          serviceProviderCode: provider.profile.providerCode!,
          quoteStatusName: 'Awaiting Confirmation'
        };
        unwrapResult(await dispatch(addLandTransportServiceQuoteAsync(transportQuote)));
      } else if (requirementType === RequirementType.Freight) {
        const freightQuote: FreightForwardQuote = {
          freightForwardServiceRequestId: requirement.id!,
          quoteRemarks,
          serviceProviderCode: provider.profile.providerCode!,
          quoteStatusName: 'Awaiting Confirmation'
        }
        unwrapResult(await dispatch(addFreightForwardServiceQuoteAsync(freightQuote)));
      } else {
        const multimodeQuote: MultiModeQuote = {
          multiModeServiceRequestId: requirement.id!,
          quoteRemarks,
          serviceProviderCode: provider.profile.providerCode!,
          quoteStatusName: 'Awaiting Confirmation'
        }
        unwrapResult(await dispatch(addMultiModeServiceQuoteAsync(multimodeQuote)));
      }
      dispatch(displayAlert("Successfully submitted a service quote", "success"));
      setQuote(false);
    } catch(err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  };

  const reqView = (item: Transport | Freight) => {
    return (
      <>
        <IonCardHeader>
          <IonCardTitle>
            To {item.destinationPlace}, {item.destinationProvinceStateName}.{" "}
            {item.destinationCountryName}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel>
                <IonText>{item.commodityDescription}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Shipment Date
              </IonNote>
              <IonLabel>
                <IonText><Moment format='DD-MM-YYYY'>{item.shipmentDate}</Moment></IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Origin
              </IonNote>
              <IonLabel>
                <IonText>
                  {item.originPlace}, {item.originProvinceStateName},{" "}
                  {item.originCountryName}
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Scope
              </IonNote>
              <IonLabel>
                <IonText>{item.incoTermsRemarks}</IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Weight
              </IonNote>
              <IonLabel>
                <IonText>
                  {item.totalWeight} {item.measurementName}
                </IonText>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonNote slot="start" color="primary">
                Cargo Dimension Type
              </IonNote>
              <IonLabel>
                <IonText>{item.cargoDimensionTypeName}</IonText>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonCardContent>
      </>
    );
  };

  return (
    <IonCard color='secondary'>
      <IonItem color='warning'><IonLabel>Quote due: <Moment format='DD-MM-YYYY'>{requirement.quoteDueDate}</Moment></IonLabel></IonItem>
      {requirementType === RequirementType.MultiMode ? (
        <>
          {reqView((requirement as MultiModeRequest).landTransportServiceDTO)}
          {reqView((requirement as MultiModeRequest).freightForwardServiceDTO)}
        </>
      ) : requirementType === RequirementType.Transport ? (
        reqView((requirement as TransportRequest).landTransportServiceDTO)
      ) : (
        reqView((requirement as FreightForwardRequest).freightForwardServiceDTO)
      )}
      {requirement.quoteGiven ? (
        <IonItem color='primary'>
          <IonLabel>Quote submitted</IonLabel>
        </IonItem>
      ) : (
        <IonItem lines="none">
          <IonLabel>Submit Quote</IonLabel>
          <IonToggle
            slot="start"
            checked={quote}
            onIonChange={(e) => setQuote(e.detail.checked)}
          />
        </IonItem>
      )}
      {quote && (
        <>
          <IonItem>
            <IonLabel position="stacked">
              Quote Details<IonText color="danger">*</IonText>
            </IonLabel>
            <IonTextarea
              value={quoteRemarks}
              onIonChange={(e) => setQuoteRemarks(e.detail.value!)}
            ></IonTextarea>
          </IonItem>
          <IonButton expand='block' onClick={() => onSubmit()}>
            <IonIcon slot="start" icon={calculatorOutline}></IonIcon> Submit
            Quote
          </IonButton>
        </>
      )}
    </IonCard>
  );
};

export default FleetDashboardContainer;
