import { emptySplitApi } from '.'
import { CargoDimensionType } from '../types'

export const cargoDimensionTypeApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getCargoDimensionTypes: build.query<CargoDimensionType[], void>({
      query: () => 'cargodimensiontypes',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CargoDimensionType' as const, id })),
              { type: 'CargoDimensionType', id: 'LIST' },
            ]
          : [{ type: 'CargoDimensionType', id: 'LIST' }],
    }),
    addCargoDimensionType: build.mutation<CargoDimensionType, Partial<CargoDimensionType>>({
      query: (body) => ({
        url: `cargodimensiontypes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'CargoDimensionType', id: 'LIST' }],
    }),
    getCargoDimensionType: build.query<CargoDimensionType, string>({
      query: (id) => `cargodimensiontypes/${id}`,
      providesTags: (result, error, id) => [{ type: 'CargoDimensionType', id }],
    }),
    updateCargoDimensionType: build.mutation<void, Pick<CargoDimensionType, 'id'> & Partial<CargoDimensionType>>({
      query: ({ id, ...patch }) => ({
        url: `cargodimensiontypes/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'CargoDimensionType', id }],
    }),
    deleteCargoDimensionType: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `cargodimensiontypes/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'CargoDimensionType', id }],
    }),
  }),
})

export const {
  useGetCargoDimensionTypeQuery,
  useGetCargoDimensionTypesQuery,
  useAddCargoDimensionTypeMutation,
  useUpdateCargoDimensionTypeMutation,
  useDeleteCargoDimensionTypeMutation,
} = cargoDimensionTypeApi
