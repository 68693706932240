import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React from "react";
const AdminDashboardContainer: React.FC = () => {
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonLabel>Providers</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/businessProviders">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Business Providers
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/serviceProviders">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Service Providers
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/agents">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">Agents</IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonLabel>Master Data</IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/businessCategories">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Business Categories
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/measurements">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Measurements
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/countries">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">Countries</IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/states">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">States</IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/serviceCategories">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Service Categories
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/serviceregions">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Service Regions
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/cargotypes">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Cargo Types
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/cargodimensiontypes">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Cargo Dimension Types
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonCard routerLink="/admin/servicequotestatustypes">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Service Quote Status Types
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
        <IonCol>
          <IonCard routerLink="/admin/servicerequeststatustypes">
            <IonCardHeader>
              <IonCardTitle className="ion-text-center">
                Service Request Status Types
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default AdminDashboardContainer;
