import {
  IonAlert,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  createOutline,
  createSharp,
  fingerPrintOutline,
  fingerPrintSharp,
  homeOutline,
  homeSharp,
  logOutOutline,
  logOutSharp,
} from "ionicons/icons";
import "./Menu.css";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { performLogout } from "../redux/authSlice";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  type: string;
}

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/shipper/welcome",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    type: "shipper",
  },
  {
    title: "Edit Profile",
    url: "/shipper/profile/edit",
    iosIcon: createOutline,
    mdIcon: createSharp,
    type: "shipper",
  },
  {
    title: "Change Password",
    url: "/shipper/profile/password",
    iosIcon: fingerPrintOutline,
    mdIcon: fingerPrintSharp,
    type: "shipper",
  },
  {
    title: "Home",
    url: "/fleet/welcome",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    type: "fleet",
  },
  {
    title: "Edit Profile",
    url: "/fleet/profile/edit",
    iosIcon: createOutline,
    mdIcon: createSharp,
    type: "fleet",
  },
  {
    title: "Change Password",
    url: "/fleet/profile/password",
    iosIcon: fingerPrintOutline,
    mdIcon: fingerPrintSharp,
    type: "fleet",
  },
  {
    title: "Home",
    url: "/admin/welcome",
    iosIcon: homeOutline,
    mdIcon: homeSharp,
    type: "admin",
  },
];

const Menu: React.FC = () => {
  const dispatch = useAppDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const location = useLocation();
  const userDetails = useAppSelector(state => state.auth.loginResponse);
  const name = userDetails?.user?.name;

  return (
    <IonMenu contentId="main" type="overlay" side="end">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Welcome</IonListHeader>
          <IonNote>{name}</IonNote>
          {appPages.map((appPage, index) => {
            {
              return (
                <div key={index}>
                  {location.pathname.includes(appPage.type) && (
                    <IonMenuToggle autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === appPage.url ? "selected" : ""
                        }
                        routerLink={appPage.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon
                          slot="start"
                          ios={appPage.iosIcon}
                          md={appPage.mdIcon}
                        />
                        <IonLabel>{appPage.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  )}
                </div>
              );
            }
          })}
          <IonMenuToggle autoHide={false}>
            <IonItem onClick={() => setShowAlert(true)} lines='none'>
              <IonLabel>Logout</IonLabel>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
            </IonItem>
          </IonMenuToggle>
        </IonList>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass="my-custom-class"
          subHeader={"Logout"}
          message={"Confirm logout?"}
          buttons={[
            {
              text: "Ok",
              handler: () => {
                dispatch(performLogout());
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
