import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { Agent } from "../../types";
import BaseProviderCardContainer from "./BaseProviderCardContainer";

interface ContainerProps {
  provider: Agent;
}
const AgentCardContainer: React.FC<ContainerProps> = ({ provider }) => {
  return (
    <IonList>
      <IonItem>
        <IonLabel>Agent Code</IonLabel>
        <IonLabel>{provider.agentCode}</IonLabel>
      </IonItem>
      <BaseProviderCardContainer provider={provider} />
    </IonList>
  );
};
export default AgentCardContainer;
