import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ShipperContainer from "../../components/shipper/ShipperContainer";

const Shipper: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="home" />
          </IonButtons>
          <IonTitle>Business Provider</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Bussiness Provider</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ShipperContainer />
      </IonContent>
      <IonFooter className='ion-padding'>
        <IonLabel className='ion-text-wrap'>
          <IonText color='primary'>
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default Shipper;
