import {
  IonButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddServiceRegionMutation, useUpdateServiceRegionMutation } from "../../services/serviceRegions";
import { ServiceRegion } from "../../types";
interface ContainerProps {
  serviceRegion?: ServiceRegion;
  setClose: any;
}

const ManageServiceRegions: React.FC<ContainerProps> = ({
  serviceRegion,
  setClose,
}) => {
  const dispatch = useAppDispatch();
  const { control, reset, handleSubmit } = useForm<ServiceRegion>();
  const [addServiceRegion] = useAddServiceRegionMutation();
  const [updateServiceRegion] = useUpdateServiceRegionMutation();

  useEffect(() => {
    reset(serviceRegion);
  }, [serviceRegion]);
  const initialValues = {
    id: 0,
    name: "",
    description: "",
  };
  const onManageServiceRegions: SubmitHandler<ServiceRegion> = async (data) => {
    try {
      if (serviceRegion) {
        await updateServiceRegion(data).unwrap();
      } else {
        await addServiceRegion(data).unwrap();
        reset(initialValues);
      }
      setClose(false);
    } catch (err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManageServiceRegions)}>
      <IonGrid>
        <IonItem className="ion-padding">
          <IonLabel>{serviceRegion ? "Edit" : "Add"} Service Region</IonLabel>
          <IonButton
            slot="end"
            className="ion-no-border"
            onClick={() => setClose(false)}
            color="danger"
          >
            <IonIcon
              slot="start"
              size="small"
              icon={closeCircleSharp}
            ></IonIcon>
            close
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Name</IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>

        <IonButton expand="block" type="submit">
          {serviceRegion ? "Update" : "Add"}
        </IonButton>
      </IonGrid>
    </form>
  );
};

export default ManageServiceRegions;
