import React, { useState } from "react";
import {
  Agent,
  ServiceProvider,
  BusinessProvider,
} from "../../types";
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
} from "@ionic/react";

import BusinessProviderCardContainer from "./BusinessProviderCardContainer";
import { useLocation } from "react-router";
import ServiceProviderCardContainer from "./ServiceProviderCardContainer";
import AgentCardContainer from "./AgentCardContainer";
import { eyeOffOutline, eyeOutline, trashBinOutline } from "ionicons/icons";
import { useDeleteAgentMutation, useDeleteBusinessProviderMutation, useDeleteServiceProviderMutation, useGetAgentsQuery, useGetBusinessProvidersQuery, useGetServiceProvidersQuery } from "../../services/admin";

const ProviderDetailsComponent: React.FC = () => {
  const location = useLocation();
  const {data: businesssProvidersList} = useGetBusinessProvidersQuery();
  const {data: serviceProvidersList} = useGetServiceProvidersQuery();
  const {data: agentsList} = useGetAgentsQuery();
  const [deleteBusinessProvider] = useDeleteBusinessProviderMutation();
  const [deleteServiceProvider] = useDeleteServiceProviderMutation();
  const [deleteAgent] = useDeleteAgentMutation();

  const [show, setShow] = useState(-1);
  const [setAlert, showSetAlert] = useState<boolean>(false);
  const [selectedBusinessProvider, setSelectedBusinessProvider] =
    useState<BusinessProvider>({} as BusinessProvider);
  const [selectedServiceProvider, setSelectedServiceProvider] =
    useState<ServiceProvider>({} as ServiceProvider);
  const [selectedAgent, setSelectedAgent] = useState<Agent>(
    {} as Agent
  );
  const loadFullData = (index: number) => {
    if (index !== show) {
      setShow(index)
    } else {
      setShow(-1)
    }
  };

  const onDeleteBusinessProvider = (businessProvider: BusinessProvider) => {
    showSetAlert(true);
    setSelectedBusinessProvider(businessProvider);
  };
  const onDeleteServiceProvider = (serviceProvider: ServiceProvider) => {
    showSetAlert(true);
    setSelectedServiceProvider(serviceProvider);
  };
  const onDeleteAgent = (agent: Agent) => {
    showSetAlert(true);
    setSelectedAgent(agent);
  };
  return (
    <div>
      <IonList className="">
        <IonItem color="primary" className="ion-align-items-stretch">
          <IonLabel>Company</IonLabel>
          <IonLabel>Contact Name</IonLabel>
          <IonLabel>Mobile</IonLabel>
          <IonLabel>Action</IonLabel>
        </IonItem>
        {location.pathname.includes("businessProviders")
          ? businesssProvidersList?.map((provider) => {
              return (
                <IonList key={provider.id} className="ion-no-padding">
                  <IonItem color="light">
                    <IonLabel>{provider.companyName}</IonLabel>
                    <IonLabel>{provider.contactPersonName}</IonLabel>
                    <IonLabel>{provider.mobileNumber}</IonLabel>
                    <IonLabel className="ion-no-wrap">
                      {show !== provider.id ? (
                        <IonButton
                          size="small"
                          onClick={() => provider.id && loadFullData(provider.id)}
                        >
                          <IonIcon slot="start" icon={eyeOutline}></IonIcon>
                          View Details
                        </IonButton>
                      ) : (
                        <IonButton size="small" onClick={() => setShow(-1)}>
                          <IonIcon icon={eyeOffOutline}></IonIcon>
                          Hide Details
                        </IonButton>
                      )}
                      <IonButton
                        onClick={() => onDeleteBusinessProvider(provider)}
                      >
                        <IonIcon slot="start" icon={trashBinOutline}></IonIcon>
                        Delete
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                  {show === provider.id && (
                    <BusinessProviderCardContainer provider={provider} />
                  )}
                </IonList>
              );
            })
          : location.pathname.includes("serviceProviders")
          ? serviceProvidersList?.map((provider) => {
              return (
                <IonList key={provider.id} className="ion-no-padding">
                  <IonItem color="light">
                    <IonLabel>{provider.companyName}</IonLabel>
                    <IonLabel>{provider.contactPersonName}</IonLabel>
                    <IonLabel>{provider.mobileNumber}</IonLabel>
                    <IonLabel className="ion-no-wrap">
                      {show !== provider.id ? (
                        <IonButton
                          size="small"
                          onClick={() => provider.id && loadFullData(provider.id)}
                        >
                          <IonIcon slot="start" icon={eyeOutline}></IonIcon>
                          View Details
                        </IonButton>
                      ) : (
                        <IonButton size="small" onClick={() => setShow(-1)}>
                          <IonIcon icon={eyeOffOutline}></IonIcon>
                          Hide Details
                        </IonButton>
                      )}
                      <IonButton
                        onClick={() => onDeleteServiceProvider(provider)}
                      >
                        <IonIcon slot="start" icon={trashBinOutline}></IonIcon>
                        Delete
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                  {show === provider.id && (
                    <ServiceProviderCardContainer provider={provider} />
                  )}
                </IonList>
              );
            })
          : agentsList?.map((provider) => {
              return (
                <IonList key={provider.id} className="ion-no-padding">
                  <IonItem color="light">
                    <IonLabel>{provider.companyName}</IonLabel>
                    <IonLabel>{provider.contactPersonName}</IonLabel>
                    <IonLabel>{provider.mobileNumber}</IonLabel>
                    <IonLabel className="ion-no-wrap">
                      {show !== provider.id ? (
                        <IonButton
                          size="small"
                          onClick={() => provider.id && loadFullData(provider.id)}
                        >
                          <IonIcon slot="start" icon={eyeOutline}></IonIcon>
                          View Details
                        </IonButton>
                      ) : (
                        <IonButton size="small" onClick={() => setShow(-1)}>
                          <IonIcon icon={eyeOffOutline}></IonIcon>
                          Hide Details
                        </IonButton>
                      )}
                      <IonButton onClick={() => onDeleteAgent(provider)}>
                        <IonIcon slot="start" icon={trashBinOutline}></IonIcon>
                        Delete
                      </IonButton>
                    </IonLabel>
                  </IonItem>
                  {show === provider.id && (
                    <AgentCardContainer provider={provider} />
                  )}
                </IonList>
              );
            })}
        <IonAlert
          isOpen={setAlert}
          onDidDismiss={() => showSetAlert(false)}
          subHeader={
            location.pathname.includes("businessProvider")
              ? "Delete Business Provider"
              : location.pathname.includes("servciceProviders")
              ? "Delete Service Provider"
              : "Delete Agent"
          }
          message={"Confirm delete?"}
          buttons={[
            {
              text: "Ok",
              handler: () => {
                location.pathname.includes("businessProviders") ? selectedBusinessProvider.id &&
                 deleteBusinessProvider(selectedBusinessProvider.id)
                : location.pathname.includes("serviceProviders") ? selectedServiceProvider.id &&
                 deleteServiceProvider(selectedServiceProvider.id)
                : selectedAgent.id && deleteAgent(selectedAgent.id);
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </IonList>
    </div>
  );
};
export default ProviderDetailsComponent;
