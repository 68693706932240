import {
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import ExploreContainer from "../components/ExploreContainer";
import { useAppSelector } from "../hooks";
import "./Home.css";

const Home: React.FC = () => {
  const history = useHistory();
  const userDetails = useAppSelector(state => state.auth.loginResponse);
  useEffect(() => {
    if (userDetails.type === 'BUSINESS_PROVIDER') {
      history.replace('/shipper/welcome');
    } else if (userDetails.type === 'SERVICE_PROVIDER') {
      history.replace('/fleet/welcome');
    } else if(userDetails.type==='AGENT'){
      history.replace('/agent/welcome');
    } else if (userDetails.user) {
      history.replace('/admin/welcome');
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Welcome</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Welcome</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer />
      </IonContent>
      <IonFooter className='ion-padding'>
        <IonLabel className='ion-text-wrap'>
          <IonText color='primary'>
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
