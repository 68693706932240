import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { AlertMessage } from '../types';
import { v4 as uuidv4 } from 'uuid';

export interface AlertState {
  alerts: AlertMessage[];
}

const initialState: AlertState = {
  alerts: []
}

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertMessage>) => {
      state.alerts = [...state.alerts, action.payload]
    },
    removeAlert: (state, action: PayloadAction<string>) => {
      state.alerts = state.alerts.filter(alert => alert.id !== action.payload)
    }
  }
});

export const {setAlert, removeAlert} = alertSlice.actions;

export const displayAlert = (msg: string, alertType: string, timeout = 5000): AppThunk => (dispatch) => {
  const id = uuidv4();
  dispatch(setAlert({ msg, alertType, id }));
  setTimeout(() => dispatch(removeAlert(id)), timeout);
};

export default alertSlice.reducer;