import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { eyeOutline } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../hooks";

interface ContainerProps {}

const ShipperDashboardContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  const provider = useAppSelector(state => state.auth.loginResponse);
  const transportRequests = useAppSelector(state => state.serviceRequest.transportRequests);
  const freightForwardRequests = useAppSelector(state => state.serviceRequest.freightForwardRequests);
  const multiModeRequests = useAppSelector(state => state.serviceRequest.multiModeRequests);
  const requestCount = transportRequests.filter(item => item.businessProviderCode === provider.profile.providerCode).length +
  freightForwardRequests.filter(item => item.businessProviderCode === provider.profile.providerCode).length +
  multiModeRequests.filter(item => item.businessProviderCode === provider.profile.providerCode).length;

  return (
    <IonGrid className="ion-padding">
      <IonRow>
        <IonCol size="6">
          <IonItem>
            <IonLabel>Requirements Posted</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>{requestCount}
            </IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonButton disabled={requestCount < 1} onClick={() => history.push("/shipper/requirements")}>
            <IonIcon slot="start" icon={eyeOutline}></IonIcon> View
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <IonItem>
            <IonLabel>Quote Received</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>900</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonButton onClick={() => history.push("/shipper/requirements")}>
            <IonIcon slot="start" icon={eyeOutline}></IonIcon> View
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <IonItem>
            <IonLabel>Quote Accepted</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>800</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonButton onClick={() => history.push("/shipper/requirements")}>
            <IonIcon slot="start" icon={eyeOutline}></IonIcon> View
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <IonItem>
            <IonLabel>Quote Not Accepted</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>100</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonButton onClick={() => history.push("/shipper/requirements")}>
            <IonIcon slot="start" icon={eyeOutline}></IonIcon> View
          </IonButton>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6">
          <IonItem>
            <IonLabel>Charges Paid</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonLabel>50</IonLabel>
          </IonItem>
        </IonCol>
        <IonCol>
          <IonButton onClick={() => history.push("/shipper/requirements")}>
            <IonIcon slot="start" icon={eyeOutline}></IonIcon> View
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ShipperDashboardContainer;
