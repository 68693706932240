import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import moment from "moment";
import { useState } from "react";
import { AlliedService } from "../../types";

interface ContainerProps {
  service: string;
}

const AlliedServicesContainer: React.FC<ContainerProps> = ({ service }) => {
  const [alliedService, setAlliedService] = useState<AlliedService>(
    {} as AlliedService
  );
  const [estimatedDate, setEstimatedDate] = useState("");
  const [whType, setWhType] = useState("");
  const [cfsType, setCfsType] = useState("");
  const [mheType, setMheType] = useState("");

  const minDate = moment().add(3, "days").format("YYYY-MM-DD");
  const maxDate = moment().add(6, "months").format("YYYY-MM-DD");

  const handleOnChange = (e: any) => {
    setAlliedService({
      ...alliedService,
      [e.target.name]: e.detail.value && e.detail.value,
    });
  };

  return (
    <>
      <IonItem>
        <IonLabel>{service} details </IonLabel>
      </IonItem>
      <IonList>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Domestic or EX IM<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="domesticOrEXIM"
            title="domesticOrEXIM"
            value={alliedService.serviceType}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            port of Loading<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="portOfLoading"
            title="Port of Loading"
            value={alliedService.originPlace}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Loading Country<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="loadingCountry"
            title="Landing Country"
            value={alliedService.originCountryName}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Port of Destination<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="portOfDestination"
            title="Port of Destination"
            value={alliedService.destinationPlace}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Destination Country<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="destinationCountry"
            title="Destination Country"
            value={alliedService.destinationCountryName}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Inco Terms<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="incoTerms"
            title="Inco Terms"
            value={alliedService.incoTermsRemarks}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Commodity Description<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="commodityDescription"
            title="Commodity Description"
            value={alliedService.commodityDescription}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Container Type (General/Break Bulk/Container)
            <IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="containerType"
            title="Cont"
            value={alliedService.cargoTypeName}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
             Weight(KG/MT/CBM/FRT)<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="weight"
            title="Weight"
            value={alliedService.totalWeight}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
             Nos/Pkgs/Ltrs/Containers<IonText color="danger">*</IonText>
          </IonLabel>
          <IonInput
            type="text"
            name="weightType"
            title="Weight Type"
            value={alliedService.measurementName}
            onIonChange={(e) => handleOnChange(e)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            ODC/Non-ODC<IonText color="danger">*</IonText>
          </IonLabel>
          <IonRadioGroup
            name="odc"
            value={alliedService.cargoDimensionTypeName}
            onIonChange={(e) => handleOnChange(e)}
          >
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">ODC</IonLabel>
              <IonRadio mode="md" slot="start" value="ODC" />
            </IonItem>
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">Non-ODC</IonLabel>
              <IonRadio mode="md" slot="start" value="Non-ODC" />
            </IonItem>
          </IonRadioGroup>
        </IonItem>
        {service === "CHA" && (
          <IonItem>
            <IonLabel position="stacked" className="ion-text-wrap">
              ETA/ETD<IonText color="danger">*</IonText>
            </IonLabel>
            <IonDatetime
              name="differentServicesAnswer"
              value={estimatedDate}
              displayFormat="DD MMM YYYY"
              min={minDate}
              max={maxDate}
              onIonChange={(e: any) => setEstimatedDate(e.detail.value)}
            ></IonDatetime>
          </IonItem>
        )}
        {service === "WH" && (
          <IonItem>
            <IonLabel position="stacked">bonding/non bonding</IonLabel>
            <IonSelect
              value={whType}
              name="differentServicesAnswer"
              onIonChange={(e: any) => setWhType(e.detail.value)}
            >
              <IonSelectOption value="Bonding">Bonding</IonSelectOption>
              <IonSelectOption value="Non Bonding">Non Bonding</IonSelectOption>
            </IonSelect>
          </IonItem>
        )}
        {service === "CFS" && (
          <IonItem>
            <IonLabel position="stacked">
              bonding/non bonding/cold Storage
            </IonLabel>
            <IonSelect
              value={cfsType}
              name="differentServicesAnswer"
              onIonChange={(e: any) => setCfsType(e.detail.value)}
            >
              <IonSelectOption value="Bonding">Bonding</IonSelectOption>
              <IonSelectOption value="Non Bonding">Non Bonding</IonSelectOption>
              <IonSelectOption value="Cold Storage">
                Cold Storage
              </IonSelectOption>
            </IonSelect>
          </IonItem>
        )}
        {service === "MHE" && (
          <IonItem>
            <IonLabel position="stacked">battery Truck/forklift/crane</IonLabel>
            <IonSelect
              value={mheType}
              name="differentServicesAnswer"
              onIonChange={(e: any) => setMheType(e.detail.value)}
            >
              <IonSelectOption value="Battery Truck">
                Battery Truck
              </IonSelectOption>
              <IonSelectOption value="forKlift">forKlift</IonSelectOption>
              <IonSelectOption value="Crane">Crane</IonSelectOption>
            </IonSelect>
          </IonItem>
        )}
      </IonList>
    </>
  );
};

export default AlliedServicesContainer;
