import {
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import React, { useState } from "react";
import { SERVICE_PROVIDER } from "../../constants";
import ChooseLogisticsAlliedService from "./ChooseLogisticsAlliedService";
import ChooseLogisticsService from "./ChooseLogisticsService";

const ChooseService: React.FC = () => {
  const [service, setService] = useState("");

  return (
    <>
      <IonItem>
        <IonLabel>Choose Service</IonLabel>
      </IonItem>

      <IonList className="ion-padding">
        <IonRadioGroup
          value={service}
          onIonChange={(e) => setService(e.detail.value)}
        >
          {SERVICE_PROVIDER.map((item) => (
            <IonItem key={item} lines="none">
              <IonLabel className="ion-text-wrap">{item}</IonLabel>
              <IonRadio mode="md" slot="start" value={item} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonList>

      {service === "Logistics Service" && <ChooseLogisticsService />}
      {service === "Logistics Allied Service" && <ChooseLogisticsAlliedService />}
      <IonItem>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </>
  );
};

export default ChooseService;
