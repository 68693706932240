import {
  IonButton,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import React from "react";
import { useAppDispatch } from '../hooks';
import { useHistory } from "react-router";
import { LoginForm } from "../types";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidator } from "../utils/validators";
import { displayAlert } from "../redux/alertSlice";
import { setAuthToken } from "../utils/authToken";
import { useLoginMutation } from '../services/auth'

const LoginContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<LoginForm>({
    resolver: yupResolver(loginValidator),
  });

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const loginResponse = await login(data).unwrap();
      localStorage.setItem('user_details', JSON.stringify(loginResponse));
      setAuthToken(loginResponse.token);
      dispatch(displayAlert('Login successful', 'success'));
      switch (loginResponse.type) {
        case 'SERVICE_PROVIDER': history.replace('/fleet/welcome');
          break;
        case 'BUSINESS_PROVIDER': history.replace("/shipper/welcome");
          break;
        case 'AGENT': history.replace("/agent/welcome");
          break;
        default: history.replace("/admin/welcome");
          break;
      }
    } catch (err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">
              Email Id<IonText color="danger">*</IonText>
            </IonLabel>
            <Controller
              name="username"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  onIonChange={(input) => onChange(input)}
                  value={value}
                  title="Email Id"
                />
              )}
            />
            {errors.username && (
              <IonText color="danger">
                <small>{errors.username.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              Password<IonText color="danger">*</IonText>
            </IonLabel>
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  onIonChange={(input) => onChange(input)}
                  value={value}
                  type="password"
                />
              )}
            />
            {errors.password && (
              <IonText color="danger">
                <small>{errors.password.message}</small>
              </IonText>
            )}
          </IonItem>
        </IonList>
        <IonButton type="submit" expand="block">
          Login
        </IonButton>
      </form>
    </div>
  );
};

export default LoginContainer;
