import {
    IonAlert,
    IonButton,
    IonCol,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
  } from "@ionic/react";
import { add, createOutline, trashBinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useDeleteServiceRegionMutation, useGetServiceRegionsQuery } from "../../services/serviceRegions";
import { ServiceRegion } from "../../types";
import ManageServiceRegions from "./ManageServiceRegions";

  const ServiceRegionsContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const {data: serviceRegions} = useGetServiceRegionsQuery();
    const [deleteServiceRegion] = useDeleteServiceRegionMutation();
    const [showAddCategory, setShowAddCategory] = useState(false);
    const [showEditCategory, setShowEditCategory] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<ServiceRegion>({} as ServiceRegion);

    const onAddClicked = () => {
      setShowEditCategory(false);
      setShowAddCategory(true);
    }

    const onDeleteClicked = (item: ServiceRegion) => {
      setSelectedCategory(item);
      setShowAlert(true);
      setShowEditCategory(false);
    }

    const onEditClicked = (item: ServiceRegion) => {
      setSelectedCategory(item);
      setShowAddCategory(false);
      setShowEditCategory(true);
    }

    const onDelete = async (id: number) => {
      try {
        await deleteServiceRegion(id).unwrap();
      } catch (err) {
        const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
        dispatch(displayAlert(message, "danger"));
      }
    }

    return (
      <>
        {showAddCategory && (
          <ManageServiceRegions setClose={setShowAddCategory} />
        )}
        {showEditCategory && (
          <ManageServiceRegions
            serviceRegion={selectedCategory}
            setClose={setShowEditCategory}
          />
        )}
        <IonGrid className="ion-padding">
          <IonRow className="header-row ion-padding-bottom ion-padding-top">
            <IonCol size="4">
              <IonLabel>Name</IonLabel>
            </IonCol>
            <IonCol size="4">
              <IonLabel>Description</IonLabel>
            </IonCol>
            <IonCol size="2" className="ion-text-center">
              <IonLabel>Edit</IonLabel>
            </IonCol>
            <IonCol size="2" className="ion-text-center">
              <IonLabel>Delete</IonLabel>
            </IonCol>
          </IonRow>
          {serviceRegions?.map((item) => (
            <IonRow key={item.id}>
              <IonCol size="4" className="ion-padding-vertical">
                <IonLabel>{item.name}</IonLabel>
              </IonCol>
              <IonCol size="4" className="ion-padding-vertical">
                <IonLabel>{item.description}</IonLabel>
              </IonCol>
              <IonCol size="2" className="ion-text-center">
                <IonButton fill="clear" onClick={() => onEditClicked(item)}>
                  <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol size="2" className="ion-text-center">
                <IonButton fill="clear" onClick={() => onDeleteClicked(item)}>
                  <IonIcon
                    color="danger"
                    slot="icon-only"
                    icon={trashBinOutline}
                  ></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => onAddClicked()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          subHeader={"Delete Service Region"}
          message={"Confirm delete?"}
          buttons={[
            {
              text: "Ok",
              handler: () => onDelete(selectedCategory.id),
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </>
    );
  };
  export default ServiceRegionsContainer;


