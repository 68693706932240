import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddServiceRequestStatusMutation, useUpdateServiceRequestStatusMutation } from "../../services/serviceRequestStatus";
import { ServiceRequestStatus } from "../../types";
interface ContainerProps {
  serviceRequestStatus?: ServiceRequestStatus;
  setClose: any;
}

const ManageServiceRequestStatusType: React.FC<ContainerProps> = ({
  serviceRequestStatus,
  setClose,
}) => {
  const dispatch = useAppDispatch();
  const { control, reset, handleSubmit } = useForm<ServiceRequestStatus>();
  const [addServiceRequestStatus] = useAddServiceRequestStatusMutation();
  const [updateServiceRequestStatus] = useUpdateServiceRequestStatusMutation();

  useEffect(() => {
    reset(serviceRequestStatus);
  }, [serviceRequestStatus]);

  const initialValues = {
    id: 0,
    name: "",
    description: "",
  };

  const onManageServiceRequestStatusType: SubmitHandler<ServiceRequestStatus> = async (data) => {
    try {
      if (serviceRequestStatus) {
        await updateServiceRequestStatus(data).unwrap();
      } else {
        await addServiceRequestStatus(data).unwrap();
        reset(initialValues);
      }
      setClose(false);
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManageServiceRequestStatusType)}>
      <IonList className="ion-padding">
      <IonItem lines='none'>
        <IonLabel>{serviceRequestStatus ? "Edit" : "Add"} Service Request Status Type</IonLabel>
        <IonButton
          slot="end"
          className="ion-no-border"
          onClick={() => setClose(false)}
          color="danger"
        >
          <IonIcon slot="start" size="small" icon={closeCircleSharp}></IonIcon>
          close
        </IonButton>
      </IonItem>
        <IonItem>
          <IonLabel position="floating">Name <IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Description</IonLabel>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonButton expand="block" type="submit">
          {serviceRequestStatus ? "Update" : "Add"}
        </IonButton>
      </IonList>
    </form>
  );
};
export default ManageServiceRequestStatusType;
