import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import { useGetCountriesQuery } from "../../services/countries";
import { useGetStatesQuery } from "../../services/states";
import { useGetMeasurementsQuery } from "../../services/measurements";
import { useGetCargoDimensionTypesQuery } from "../../services/cargoDimensionTypes";
import { useGetCargoTypesQuery } from "../../services/cargoTypes";

interface ContainerProps {
  control: any,
  errors: any
}

const FreightContainer: React.FC<ContainerProps> = ({control, errors}) => {
  const minDate = moment().add(3, "days").format("YYYY-MM-DD");
  const maxDate = moment().add(6, "months").format("YYYY-MM-DD");
  const {data: countries} = useGetCountriesQuery();
  const {data: states} = useGetStatesQuery();
  const {data: measurements} = useGetMeasurementsQuery();
  const {data: dimensionTypes} = useGetCargoDimensionTypesQuery();
  const {data: cargoTypes} = useGetCargoTypesQuery();

  const userInput = (onChange: any, onBlur: any, value: any) => (
    <IonInput
      onBlur={onBlur}
      onIonChange={(input) => onChange(input)}
      value={value}
    />
  )

  return (
    <>
      <IonItem>
        <IonLabel>Freight Forwarding Details</IonLabel>
      </IonItem>
      <IonList>
        <IonItem>
          <IonLabel position="stacked">Port of Landing<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="freightForwardServiceDTO.originPlace"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
          />
          {errors.freightForwardServiceDTO?.originPlace && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.originPlace.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Landing Country<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.originCountryName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {countries?.map((country) => {
                  return (
                    <IonSelectOption key={country.id} value={country.name}>
                      {country.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.originCountryName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.originCountryName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Landing State<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.originProvinceStateName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {states?.map((state) => {
                  return (
                    <IonSelectOption key={state.id} value={state.name}>
                      {state.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.originProvinceStateName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.originProvinceStateName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Port of Destination<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.destinationPlace"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
          />
          {errors.freightForwardServiceDTO?.destinationPlace && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.destinationPlace.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Destination Country<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.destinationCountryName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {countries?.map((country) => {
                  return (
                    <IonSelectOption key={country.id} value={country.name}>
                      {country.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.destinationCountryName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.destinationCountryName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            Destination State<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.destinationProvinceStateName"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {states?.map((state) => {
                  return (
                    <IonSelectOption key={state.id} value={state.name}>
                      {state.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.destinationProvinceStateName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.destinationProvinceStateName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Commodity Description<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.commodityDescription"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
          />
          {errors.freightForwardServiceDTO?.commodityDescription && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.commodityDescription.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Container Type (General/Break Bulk/Container)<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.cargoTypeName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {cargoTypes?.map((cargoType) => {
                  return (
                    <IonSelectOption
                      key={cargoType.id}
                      value={cargoType.name}
                    >
                      {cargoType.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.cargoTypeName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.cargoTypeName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Cargo Dimension Type<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.cargoDimensionTypeName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {dimensionTypes?.map((dimension) => {
                  return (
                    <IonSelectOption
                      key={dimension.id}
                      value={dimension.name}
                    >
                      {dimension.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.cargoDimensionTypeName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.cargoDimensionTypeName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
           Weight(KG/MT/CBM/FRT)<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.totalWeight"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
          />
          {errors.freightForwardServiceDTO?.totalWeight && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.totalWeight.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
           Nos/Pkgs/Ltrs/Containers<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.measurementName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {measurements?.map((measurement) => {
                  return (
                    <IonSelectOption
                      key={measurement.id}
                      value={measurement.name}
                    >
                      {measurement.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
          {errors.freightForwardServiceDTO?.measurementName && (
            <IonText color="danger">
              <small>{errors.freightForwardServiceDTO.measurementName.message}</small>
            </IonText>
          )}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" className="ion-text-wrap">
            Shipment Date<IonText color="danger">*</IonText>
          </IonLabel>
          <Controller
            name="freightForwardServiceDTO.shipmentDate"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonDatetime
                displayFormat="DD MMM YYYY"
                min={minDate}
                max={maxDate}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              ></IonDatetime>
            )}
          />
         {errors.freightForwardServiceDTO?.shipmentDate && (
          <IonText color="danger">
            <small>{errors.freightForwardServiceDTO.shipmentDate.message}</small>
          </IonText>
        )}
        </IonItem>
      <IonItem>
        <IonLabel position="stacked" className="ion-text-wrap">
          Scope of Service/Conditions
        </IonLabel>
        <Controller
          name="freightForwardServiceDTO.incoTermsRemarks"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked" className="ion-text-wrap">
          Packing list remarks
        </IonLabel>
        <Controller
          name="freightForwardServiceDTO.packingListRemarks"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => userInput(onChange, onBlur, value)}
        />
      </IonItem>
      </IonList>
    </>
  );
};

export default FreightContainer;

