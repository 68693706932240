import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TransportRequest } from "../../types";
import { transportRequestValidator } from "../../utils/validators";
import moment from "moment";
import { useHistory } from "react-router-dom";
import TransportationContainer from "./TransportationContainer";
import {
  IonButton,
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import { addLandTransportServiceRequestAsync } from "../../redux/serviceRequestSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayAlert } from "../../redux/alertSlice";

interface ContainerProps {}
const TransportRequestContainer: React.FC<ContainerProps> = () => {
  const minDate = moment().add(3, "days").format("YYYY-MM-DD");
  const maxDate = moment().add(6, "months").format("YYYY-MM-DD");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const provider = useAppSelector(state => state.auth.loginResponse);
  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<TransportRequest>({
    defaultValues: {
      landTransportServiceDTO: {
        originCountryName: "India",
        destinationCountryName: "India",
      },
    },
    resolver: yupResolver(transportRequestValidator),
  });

  const onSubmit: SubmitHandler<TransportRequest> = async (data) => {
    const transportRequest = {
      ...data,
      businessProviderCode: provider.profile.providerCode!,
      requestStatusName: "Awaiting Quote",
      quoteDueDate: moment(data.quoteDueDate).format("YYYY-MM-DD"),
      landTransportServiceDTO: {
        ...data.landTransportServiceDTO,
        // We need to only send date to the backend
        shipmentDate: moment(data.landTransportServiceDTO.shipmentDate).format(
          "YYYY-MM-DD"
        ),
      },
    };
    try {
      unwrapResult(await dispatch(addLandTransportServiceRequestAsync(transportRequest)));
      dispatch(displayAlert("Successfully added a land transport service request", "success"));
      history.goBack();
    } catch (err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TransportationContainer
        control={control}
        errors={errors}
      ></TransportationContainer>
      <IonItem>
        <IonLabel position="stacked" className="ion-text-wrap">
          Due Date for Quote<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="quoteDueDate"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonDatetime
              displayFormat="DD MMM YYYY"
              min={minDate}
              max={maxDate}
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              value={value}
            ></IonDatetime>
          )}
        />
        {errors.quoteDueDate && (
          <IonText color="danger">
            <small>{errors.quoteDueDate.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          Payment Terms (Days)<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="paymentTermDays"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonInput
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              value={value}
            />
          )}
        />
        {errors.paymentTermDays && (
          <IonText color="danger">
            <small>{errors.paymentTermDays.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonButton expand="block" type="submit">
        Submit
      </IonButton>
    </form>
  );
};

export default TransportRequestContainer;
