import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import ShipperDashboardContainer from "../../components/shipper/ShipperDashboardContainer";
import { useAppDispatch } from "../../hooks";
import { fetchAllRequests } from "../../redux/serviceRequestSlice";

const ShipperDashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllRequests());
  },[]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../home" />
          </IonButtons>
          <IonTitle>Business Provider Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Business Provider Dashbaord</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ShipperDashboardContainer />
      </IonContent>
      <IonFooter className='ion-padding'>
        <IonLabel className='ion-text-wrap'>
          <IonText color='primary'>
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default ShipperDashboard;
