import { IonButton, IonIcon, IonLabel, IonText } from "@ionic/react";
import { fileTrayOutline, globeOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import React from "react";

interface ContainerProps {
}

const ShipperPostLoginContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();
  return (
    <div className="container">
      <div className="message">
        <div className="box" onClick={() => history.push(`/shipper/new`)}>
          <IonLabel className="ion-text-wrap">
            <IonText className="description">Post Requirement</IonText>
          </IonLabel>
          <IonButton fill="clear">
            <IonIcon icon={fileTrayOutline} slot="end"></IonIcon>
          </IonButton>
        </div>
        <div className="box" onClick={() => history.push(`/shipper/dashboard`)}>
          <IonLabel className="ion-text-wrap">
            <IonText className="description">Dashboard</IonText>
          </IonLabel>
          <IonButton fill="clear">
            <IonIcon icon={globeOutline} slot="end"></IonIcon>
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default ShipperPostLoginContainer;
