import {
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { BusinessProvider } from "../../types";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BaseRegisterContainer from "../BaseRegisterContainer";
import { businessProviderRegistrationValidator } from "../../utils/validators";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddBusinessProviderMutation, useUpdateBusinessProviderMutation } from "../../services/admin";
import { useGetBusinessCategoriesQuery } from "../../services/businessCategories";
import { useGetMeasurementsQuery } from "../../services/measurements";

interface ContainerProps {
  provider? : BusinessProvider
  edit?: boolean
}

const ShipperRegisterContainer: React.FC<ContainerProps> = ({ edit, provider }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [registerBusinessProvider] = useAddBusinessProviderMutation();
  const [updateBusinessProvider] = useUpdateBusinessProviderMutation();
  const {data: businessCategories, isLoading: isCategoryLoading} = useGetBusinessCategoriesQuery();
  const {data: measurements, isLoading: isMeasurementLoading} = useGetMeasurementsQuery();

  useEffect(() => {
    reset(provider);
  }, [measurements, provider]);

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm<BusinessProvider>({
    defaultValues: provider || { countryName: 'India'},
    resolver: yupResolver(businessProviderRegistrationValidator) });

  const onSubmit: SubmitHandler<BusinessProvider> = async (data) => {
    try {
      if (edit) {
        const { referredByAgentCode, providerCode, email, panNumber, ...updatedData } = data;
        const res = await updateBusinessProvider(updatedData).unwrap();
        const userDetails = JSON.parse(localStorage.user_details);
        const updatedUserDetails = { ...userDetails, profile: res };
        localStorage.setItem('user_details', JSON.stringify(updatedUserDetails));
      } else {
        await registerBusinessProvider(data).unwrap();
      }
      dispatch(displayAlert(edit ? "Update successful" : "Register successful" , "success"));
      history.goBack();
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  if (isCategoryLoading || isMeasurementLoading) return <div>Loading...</div>

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseRegisterContainer control={control} errors={errors} edit={edit} />
      <IonItem>
        <IonLabel position="stacked">
          Business Category<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="businessCategoryNames"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonSelect
              value={value}
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              multiple={true}
            >
              {businessCategories?.map((category) => {
                return (
                  <IonSelectOption key={category.id} value={category.name}>
                    {category.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
        />
        {errors.businessCategoryNames && (
          <IonText color="danger">
            <small>{(errors.businessCategoryNames as any).message}</small>
          </IonText>
        )}
      </IonItem>
      {edit && (
        <>
          <IonItem>
            <IonLabel position="stacked">Business Provider Code</IonLabel>
            <Controller
              name="providerCode"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  disabled={edit}
                  onIonChange={(item) => onChange(item)}
                  value={value}
                />
              )}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Maximum Requirement Volume</IonLabel>
            <Controller
              name="maxRequiredVolume"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  onIonChange={(item) => onChange(item)}
                  value={value}
                />
              )}
            />
            {errors.maxRequiredVolume && (
              <IonText color="danger">
                <small>{errors.maxRequiredVolume.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Measurement Name</IonLabel>
            <Controller
              name="measurementName"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonSelect
                  value={value}
                  onBlur={onBlur}
                  onIonChange={(item) => onChange(item)}
                >
                  {measurements?.map((measurement) => {
                    return (
                      <IonSelectOption
                        key={measurement.id}
                        value={measurement.name}
                      >
                        {measurement.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
            />
            {errors.measurementName && (
              <IonText color="danger">
                <small>{errors.measurementName.message}</small>
              </IonText>
            )}
          </IonItem>{" "}
        </>
      )}
      <IonButton type="submit" expand="block">
        {edit ? "Update" : "Register"}
      </IonButton>
    </form>
  );
};

export default ShipperRegisterContainer;
