import { configureStore, ThunkAction, Action, isRejectedWithValue,  Middleware } from '@reduxjs/toolkit';
import alertReducer from './redux/alertSlice';
import authReducer from './redux/authSlice';
import serviceRequestReducer from './redux/serviceRequestSlice';
import { emptySplitApi } from './services';
import { authApi } from './services/auth';

export const rtkQueryErrorLogger: Middleware =  () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!')
    console.log(action);
 }
  return next(action)
}

export const store = configureStore({
  reducer: {
    alert: alertReducer,
    auth: authReducer,
    serviceRequest: serviceRequestReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(emptySplitApi.middleware, authApi.middleware, rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;