import {
  IonAlert,
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { add, createOutline, trashBinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useDeleteMeasurementMutation, useGetMeasurementsQuery } from "../../services/measurements";
import { Measurement } from "../../types";
import ManageMeasurements from "./ManageMeasurements";

const MeasurementContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: measurements } = useGetMeasurementsQuery();
  const [deleteMeasurement] = useDeleteMeasurementMutation()
  const [showAddMeasurement, setShowAddMeasurement] = useState<boolean>(false);
  const [showEditMeasurement, setShowEditMeasurement] = useState<boolean>(false);
  const [setAlert, showSetAlert] = useState<boolean>(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState<Measurement>(
    {} as Measurement
  );

  const onAddClicked = () => {
    setShowEditMeasurement(false);
    setShowAddMeasurement(true);
  };

  const onDeleteClicked = (measurement: Measurement) => {
    setSelectedMeasurement(measurement);
    setShowAddMeasurement(false);
    setShowEditMeasurement(false);
    showSetAlert(true);
  };
  const onEditClicked = (measurement: Measurement) => {
    setSelectedMeasurement(measurement);
    setShowAddMeasurement(false);
    setShowEditMeasurement(true);
  };

  const onDelete = async (id: number) => {
    try {
      await deleteMeasurement(id).unwrap()
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  }

  return (
    <>
      {showAddMeasurement && (
        <ManageMeasurements setClose={setShowAddMeasurement} />
      )}
      {showEditMeasurement && (
        <ManageMeasurements
          setClose={setShowEditMeasurement}
          measurement={selectedMeasurement}
        />
      )}
      <IonGrid className="ion-padding">
        <IonRow  className='header-row ion-padding-bottom ion-padding-top'>
          <IonCol size="4">
            <IonLabel>Name</IonLabel>
          </IonCol>
          <IonCol size="4">
            <IonLabel>Description</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Edit</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Delete</IonLabel>
          </IonCol>
        </IonRow>
        {measurements && measurements.map((item) => (
          <IonRow key={item.id}>
          <IonCol size="4" className='ion-padding-vertical'>
            <IonLabel>{item.name}</IonLabel>
          </IonCol>
          <IonCol size="4" className='ion-padding-vertical'>
            <IonLabel>{item.description}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onEditClicked(item)}>
              <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onDeleteClicked(item)}>
              <IonIcon
                color="danger"
                slot="icon-only"
                icon={trashBinOutline}
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
    </IonGrid>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => onAddClicked()}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonAlert
        isOpen={setAlert}
        onDidDismiss={() => showSetAlert(false)}
        subHeader={"Delete Measurement"}
        message={"Confirm delete?"}
        buttons={[
          {
            text: "Ok",
            handler: () => {
              onDelete(selectedMeasurement.id);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      />
    </>
  );
};
export default MeasurementContainer;
