import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import "./ShipperContainer.css";

interface ContainerProps {}

const ShipperContainer: React.FC<ContainerProps> = () => {
  const history = useHistory();

  return (
    <div className="container">
      <IonItem lines='none'>
        <IonLabel className="ion-text-wrap">
          <IonText className="description">
            There are more than{" "}
            <span className="description">
              <strong>600</strong>
            </span>{" "}
            fleet owners registered at RightFleet
          </IonText>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="ion-text-wrap">
          <IonText className="description">
            Post your requirement by registering at RightFleet below
          </IonText>
        </IonLabel>
      </IonItem>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton onClick={() => history.push("/shipper/registration")}>
              Register
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton onClick={() => history.push("/login")}>Login</IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ShipperContainer;
