import { IonInput, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import React, { useState } from "react";


const ChangePasswordContainer: React.FC = () => {

  const [currentPassword, setCurentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <div className="container">
    <IonList>
      <IonItem>
        <IonLabel position="stacked">Current Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="currentPassword" title='Password' value={currentPassword}
          onIonChange={e => e.detail.value && setCurentPassword(e.detail.value)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">New Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="newPassword" title='Email Id' value={newPassword}
          onIonChange={e => e.detail.value && setNewPassword(e.detail.value)}></IonInput>
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Confirm Password<IonText color='danger'>*</IonText></IonLabel>
        <IonInput type="password" name="confirmPassword" title='Email Id' value={confirmPassword}
          onIonChange={e => e.detail.value && setConfirmPassword(e.detail.value)}></IonInput>
      </IonItem>
    </IonList>
    </div>
  );
};

export default ChangePasswordContainer;
