import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ShipperPostLoginContainer from "../../components/shipper/ShipperPostLoginContainer";

const ShipperPostLogin: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Welcome Business Provider</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Welcome Business Provider</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ShipperPostLoginContainer/>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default ShipperPostLogin;
