import {
  IonAlert,
  IonButton,
  IonCol,
  IonFab,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { add, createOutline, trashBinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useDeleteStateMutation, useGetStatesQuery } from "../../services/states";
import {  State } from "../../types";
import ManageStates from "./ManageStates";

const StatesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const {data: states} = useGetStatesQuery();
  const [deleteState] = useDeleteStateMutation();
  const [showAddState, setShowAddState] = useState<boolean>(false);
  const [showEditState, setShowEditState] = useState<boolean>(false);
  const [setAlert, showSetAlert] = useState<boolean>(false);
  const [selectedState, setSelectedState] = useState<State>(
    {} as State
  );

  const onAddClicked = () => {
    setShowEditState(false);
    setShowAddState(true);
  };
  const onDeleteClicked = (state: State) => {
    setSelectedState(state);
    setShowAddState(false);
    setShowEditState(false);
    showSetAlert(true);
  };
  const onEditClicked = (state: State) => {
    setSelectedState(state);
    setShowAddState(false);
    setShowEditState(true);
  };

  const onDelete = async (id: number) => {
    try {
      await deleteState(id).unwrap();
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  }

  return (
    <>
      {showAddState && (
        <ManageStates setClose={setShowAddState} />
      )}
      {showEditState && (
        <ManageStates
          setClose={setShowEditState}
          state={selectedState}
        />
      )}
      <IonGrid className="ion-padding">
        <IonRow  className='header-row ion-padding-bottom ion-padding-top'>
          <IonCol size="4">
            <IonLabel>Name</IonLabel>
          </IonCol>
          <IonCol size="2">
            <IonLabel>Code</IonLabel>
          </IonCol>
          <IonCol size="2">
            <IonLabel>Country</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Edit</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Delete</IonLabel>
          </IonCol>
        </IonRow>
        {states?.map((item) => (
          <IonRow key={item.id}>
          <IonCol size="4" className='ion-padding-vertical'>
            <IonLabel>{item.name}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-padding-vertical'>
            <IonLabel>{item.code}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-padding-vertical'>
            <IonLabel>{item.countryName}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onEditClicked(item)}>
              <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onDeleteClicked(item)}>
              <IonIcon
                color="danger"
                slot="icon-only"
                icon={trashBinOutline}
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
    </IonGrid>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={() => onAddClicked()}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>
      </IonFab>
      <IonAlert
        isOpen={setAlert}
        onDidDismiss={() => showSetAlert(false)}
        subHeader={"Delete State"}
        message={"Confirm delete?"}
        buttons={[
          {
            text: "Ok",
            handler: () => {
              onDelete(selectedState.id);
            },
          },
          {
            text: "Cancel",
            role: "cancel",
          },
        ]}
      />
    </>
  );
};

export default StatesContainer;
