export const API_ENDPOINT = process.env.REACT_APP_API_URL || 'http://localhost:8100';
export const BUSINESS_PROVIDER = [
  'Manufacturing industries',
  'Suppliers - Tier 1, Tier 2, Tier 3',
  'Traders',
  'Service Providers'
];

export const BUSINESS_PROVIDER_FLEET = [
  "Logistics Services",
"Logistics Allied Services",
"Other Services"
];

export const SERVICE_PROVIDER = [
  'Logistics Service',
  'Logistics Allied Service',
  'Other Services'
];

export const LOGISTICS_SERVICES = [
  'Transportation',
  'Freight forwarding',
  'Multimodel transportation'
];

export const TRANSPORTATION = [
  'Logistics Companies',
  'Transport Companies',
  'Transport Brokers',
  'Transport Associations',
  'Transport Stands',
  'Individual Transporters'
];

export const FREIGHT_FORWARDING = [
  'Freight Forwarding Ltd Companies',
  'Freight Forwarding Pvt Ltd Companies',
  'Freight Forwarding - Individual & Partnership'
];

export const MULTIMODAL = [
  'Transportation',
  'Freight Forwarders'
];

export const LOGISTICS_ALLIED_SERVICES = [
  'CHA',
  'WH',
  'CFS',
  'MHE'
];

export const CHA_SERVICES = [
  'CHA Ltd Companies',
  'CHA Pvt Ltd Companies',
  'CHA - Individual & Partnership'
];

export const WH_SERVICES = [
  'WH - Public ( Boned/Open /Closed)',
  'WH - Private ( Boned/Open /Closed)',
  'WH - Special ( Boned/Open /Closed)'
];

export const CFS_SERVICES = [
  'CFS - Public',
  'CFS - Private'
];
export const OTHER_SERVICES=[
"Consultancy Services",
"Training",
"Insurance - Health & Wealth & Goods",
"Certifications",
"Financing",
"Manpower",
"Packing",
"Advertising",
];

export const MHE_SERVICES = [
  'MHE - Light Equipments',
  'MHE - Heavy Equipments'
];

export const PLACES = [
     "PAN INDIA",
     "SOUTH INDIA",
     "EAST INDIA",
     "WEST INDIA",
     "NORTH INDIA"
];

export const STATE = [ "Andhra Pradesh",
                "Arunachal Pradesh",
                "Assam",
                "Bihar",
                "Chhattisgarh",
                "Goa",
                "Gujarat",
                "Haryana",
                "Himachal Pradesh",
                "Jammu and Kashmir",
                "Jharkhand",
                "Karnataka",
                "Kerala",
                "Madhya Pradesh",
                "Maharashtra",
                "Manipur",
                "Meghalaya",
                "Mizoram",
                "Nagaland",
                "Odisha",
                "Punjab",
                "Rajasthan",
                "Sikkim",
                "Tamil Nadu",
                "Telangana",
                "Tripura",
                "Uttarakhand",
                "Uttar Pradesh",
                "West Bengal",
                "Andaman and Nicobar Islands",
                "Chandigarh",
                "Dadra and Nagar Haveli",
                "Daman and Diu",
                "Delhi",
                "Lakshadweep",
                "Puducherry"];

export const BUSSINESS_CATEGORIES = [
  "Manufacturing Industries",
  "Suppliers",
  "Traders",
  "Service Providers",
];