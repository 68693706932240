export interface BaseRegistration {
  id?: number,
  companyName: string,
  contactPersonName: string,
  contactPersonDesignation?:string,
  countryName?: string,
  provinceStateName: string,
  city?: string,
  postalCode?: string,
  email?: string,
  mobileNumber: string,
  officePhoneNumber?: string,
  panNumber? : string,
  gstNumber?: string,
  webSiteURL?: string,
  referredByAgentCode?: string
};
export interface BusinessProvider extends BaseRegistration {
  businessCategoryNames:string[],
  maxRequiredVolume:number,
  measurementName:string,
  providerCode?: string,
}
export interface ServiceProvider extends BaseRegistration {
  maxCapacityVolume: number,
  measurementName: string,
  providerCode?: string,
  serviceCategoryNames:string[],
  serviceRegionNames:string[],
};
export interface Agent extends ServiceProvider {
  agentCode: string,
};
export interface BaseData {
  id: number,
  shipmentDate: string,
  incoTermsRemarks: string,
  packingListRemarks: string
};

export interface Transport extends BaseData {
  originCountryName: string,
  originProvinceStateName: string,
  originPlace: string,
  destinationCountryName: string,
  destinationProvinceStateName: string,
  destinationPlace:string,
  commodityDescription: string,
  totalWeight: string,
  measurementName?: string,
  cargoDimensionTypeName: string,
};

export interface Freight extends Transport {
  cargoTypeName: string
};

export interface BaseRequest {
  id?: number,
  quoteDueDate: string,
  paymentTermDays: string,
  businessProviderCode: string;
  requestStatusName: string;
  quoteGiven?: boolean;
}
export interface TransportRequest extends BaseRequest{
  landTransportServiceDTO: Transport;
}

export interface FreightForwardRequest extends BaseRequest {
  freightForwardServiceDTO: Freight;
}

export interface MultiModeRequest extends BaseRequest {
  landTransportServiceDTO: Transport;
  freightForwardServiceDTO: Freight;
}
export interface AlliedService extends Freight {
  serviceType: string
};

export interface ChaService extends AlliedService {
  estimatedDate: string
};

export interface CfsService extends AlliedService {
  cfsType: string
}

export interface WhService extends AlliedService {
  whType: string
}

export interface MheService extends AlliedService {
  mheType: string
}
export interface Country {
  id: number,
  name: string,
  code: string,
  isoCode: string
}
export interface State {
  id: number,
  name: string,
  code: string,
  countryName: string
}
export interface LoginForm {
  username: string,
  password: string
}

export interface User {
  id: number,
  name: string,
  username: string,
  roles: string
}
export interface LoginResponse {
  profile: BusinessProvider | ServiceProvider | Agent,
  type: string,
  user: User,
  token: string
}

export interface BusinessCategory {
  id: number,
  name: string,
  description: string
}
export interface Measurement {
  id: number,
  name: string,
  description: string
}
export interface ServiceCategory {
  id: number,
  name: string,
  description: string
}
export interface ServiceRegion {
  id: number,
  name: string,
  description: string
}

export interface CargoDimensionType {
  id: number;
  name: string;
  description: string;
}

export interface CargoType {
  id: number;
  name: string;
  description: string;
}

export interface BaseQuote {
  id?: number,
  quoteRemarks: string,
  quoteStatusName: string,
  serviceProviderCode: string
}

export interface TransportQuote extends BaseQuote {
  landTransportServiceRequestId: number;
}

export interface FreightForwardQuote extends BaseQuote {
  freightForwardServiceRequestId: number;
}

export interface MultiModeQuote extends BaseQuote {
  multiModeServiceRequestId: number;
}

export enum RequirementType  {
  Transport, Freight, MultiMode
}

export interface ServiceQuoteStatus {
  id: number;
  name: string;
  description: string;
}

export interface ServiceRequestStatus {
  id: number;
  name: string;
  description: string;
}

export interface AlertMessage {
  msg: string;
  alertType: string;
  id: string;
}