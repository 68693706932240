import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { BaseRegistration } from "../../types";

interface ContainerProps{
    provider:BaseRegistration
}
const BaseProviderCardContainer : React.FC<ContainerProps>=({provider})=>{
    return(
        <IonList>
           <IonItem>
          <IonLabel>Company Name</IonLabel>
          <IonLabel>{provider.companyName}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Contact Person</IonLabel>
          <IonLabel>{provider.contactPersonName}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Designation</IonLabel>
          <IonLabel>{provider.contactPersonDesignation}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Email</IonLabel>
          <IonLabel>{provider.email}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Mobile</IonLabel>
          <IonLabel>{provider.mobileNumber}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>City</IonLabel>
          <IonLabel>{provider.city}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Postal Code</IonLabel>
          <IonLabel>{provider.postalCode}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>State</IonLabel>
          <IonLabel>{provider.provinceStateName}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Country</IonLabel>
          <IonLabel>{provider.countryName}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel> Agent Code</IonLabel>
          <IonLabel>{provider.referredByAgentCode}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Website</IonLabel>
          <IonLabel>{provider.webSiteURL}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Phone Number</IonLabel>
          <IonLabel>{provider.officePhoneNumber}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Pan Card</IonLabel>
          <IonLabel>{provider.panNumber}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>GST Number</IonLabel>
          <IonLabel>{provider.gstNumber}</IonLabel>
        </IonItem>

        </IonList>
    )
}
export default BaseProviderCardContainer;
