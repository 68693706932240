import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ShipperRegisterContainer from "../../components/shipper/ShipperRegisterContainer";

const ShipperRegister: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Business Provider</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Business Provider</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ShipperRegisterContainer/>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default ShipperRegister;
