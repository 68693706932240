import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddBusinessCategoryMutation, useUpdateBusinessCategoryMutation } from "../../services/businessCategories";
import { BusinessCategory } from "../../types";
interface ContainerProps {
  category?: BusinessCategory
  setClose:any
}

const ManageBusinessCategory: React.FC<ContainerProps> = ({category,setClose}) => {
  const dispatch = useAppDispatch();
  const [addBusinessCategory] = useAddBusinessCategoryMutation();
  const [updateBusinessCategory] = useUpdateBusinessCategoryMutation()

  const {
    control,
    reset,
    handleSubmit,
  } = useForm<BusinessCategory>();

  useEffect(() => {
    reset(category);
  }, [category]);

  const onManageBusinessCategory: SubmitHandler<BusinessCategory> = async (data) => {
    try {
      let resAction;
      if (category) {
        await updateBusinessCategory(data).unwrap();
      } else {
        await addBusinessCategory(data).unwrap();
      }
      setClose(false);
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManageBusinessCategory)}>
      <IonList>
        <IonItem className="ion-padding">
          <IonLabel>{category ? "Edit" : "Add"} Business Category</IonLabel>
          <IonButton
            slot="end"
            className="ion-no-border"
            onClick={() => setClose(false)}
            color="danger"
          >
            <IonIcon slot="start" size="small" icon={closeCircleSharp}></IonIcon>close
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Name<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>

        <IonButton expand="block" type="submit">
          {category ? "Update" : "Add"}
        </IonButton>
      </IonList>
    </form>
  );
};
export default ManageBusinessCategory;
