import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonLabel,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import React from "react";
import StatesContainer from "../../components/admin/StatesContainer";
  
  const States: React.FC = () => {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="../admin/welcome" />
            </IonButtons>
            <IonTitle>States</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">States</IonTitle>
            </IonToolbar>
          </IonHeader>
          <StatesContainer />
        </IonContent>
        <IonFooter className="ion-padding">
          <IonLabel className="ion-text-wrap">
            <IonText color="primary">
              &copy; 2021. RightFleet. All rights reserved
            </IonText>
          </IonLabel>
        </IonFooter>
      </IonPage>
    );
  };
  export default States;
  