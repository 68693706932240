import {
    IonAlert,
    IonButton,
    IonCol,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonLabel,
    IonRow,
  } from "@ionic/react";
import { add, createOutline, trashBinOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useDeleteCountryMutation, useGetCountriesQuery } from "../../services/countries";
import { Country } from "../../types";
import ManageCountries from "./ManageCountries";

  const CountriesContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const {data: countries} = useGetCountriesQuery();
    const [deleteCountry] = useDeleteCountryMutation();
    const [showAddCountry, setShowAddCountry] = useState<boolean>(false);
    const [showEditCountry, setShowEditCountry] = useState<boolean>(false);
    const [setAlert, showSetAlert] = useState<boolean>(false);
    const [selectedCountry, setSelectedCountry] = useState<Country>({} as Country);

    const onAddClicked = () => {
      setShowEditCountry(false);
      setShowAddCountry(true);
    };
    const onDeleteClicked = (country: Country) => {
      setSelectedCountry(country);
      setShowAddCountry(false);
      setShowEditCountry(false);
      showSetAlert(true);
    };
    const onEditClicked = (country: Country) => {
      setSelectedCountry(country);
      setShowAddCountry(false);
      setShowEditCountry(true);
    };

    const onDelete = async (id: number) => {
      try {
        await deleteCountry(id).unwrap();
      } catch (err) {
        const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
        dispatch(displayAlert(message, "danger"));
      }
    }

    return (
      <>
        {showAddCountry && (
          <ManageCountries setClose={setShowAddCountry} />
        )}
        {showEditCountry && (
          <ManageCountries
            setClose={setShowEditCountry}
            country={selectedCountry}
          />
        )}
      <IonGrid className="ion-padding">
        <IonRow  className='header-row ion-padding-bottom ion-padding-top'>
          <IonCol size="4">
            <IonLabel>Name</IonLabel>
          </IonCol>
          <IonCol size="2">
            <IonLabel>Code</IonLabel>
          </IonCol>
          <IonCol size="2">
            <IonLabel>ISO Code</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Edit</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonLabel>Delete</IonLabel>
          </IonCol>
        </IonRow>
          {countries && countries.map((item) => (
          <IonRow key={item.id}>
          <IonCol size="4" className='ion-padding-vertical'>
            <IonLabel>{item.name}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-padding-vertical'>
            <IonLabel>{item.code}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-padding-vertical'>
            <IonLabel>{item.isoCode}</IonLabel>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onEditClicked(item)}>
              <IonIcon slot="icon-only" icon={createOutline}></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol size="2" className='ion-text-center'>
            <IonButton fill="clear" onClick={() => onDeleteClicked(item)}>
              <IonIcon
                color="danger"
                slot="icon-only"
                icon={trashBinOutline}
              ></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
      ))}
    </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => onAddClicked()}>
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>
        <IonAlert
          isOpen={setAlert}
          onDidDismiss={() => showSetAlert(false)}
          subHeader={"Delete Country"}
          message={"Confirm delete?"}
          buttons={[
            {
              text: "Ok",
              handler: () => {
                onDelete(selectedCountry.id)
              },
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
        />
      </>
    );
  };
  export default CountriesContainer;
