import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router";
import BusinessProviderListContainer from "../../components/admin/ProviderDetailsComponent";

const Providers: React.FC = () => {
  const location = useLocation();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../admin/welcome" />
          </IonButtons>
          <IonTitle>
            {location.pathname.includes("businessProviders")
              ? "Business Providers"
              : location.pathname.includes("serviceProviders")
              ? "Service Providers"
              : "Agents"}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              {location.pathname.includes("businessProviders")
                ? "Business Providers"
                : location.pathname.includes("serviceProviders")
                ? "Service Providers"
                : "Agents"}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <BusinessProviderListContainer />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};
export default Providers;
