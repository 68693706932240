import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonContent, IonFooter, IonLabel, IonText } from "@ionic/react";
import React from "react";
import ServiceCategoryContainer from "../../components/admin/ServiceCategoryContainer";

const ServiceCategories: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="../admin/welcome" />
          </IonButtons>
          <IonTitle>Service Categories</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Service Categories</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ServiceCategoryContainer />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
}

export default ServiceCategories;
