import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useGetCountriesQuery } from "../../services/countries";
import { useAddStateMutation, useUpdateStateMutation } from "../../services/states";
import { State } from "../../types";
interface ContainerProps {
  setClose?: any;
  state?: State;
}
const ManageStates: React.FC<ContainerProps> = ({ state, setClose }) => {
  const dispatch = useAppDispatch();
  const { control, reset, handleSubmit } = useForm<State>();
  const initialValues = {
    id: 0,
    name: "",
    code: "",
    countryName: "",
  };
  const {data: countries} = useGetCountriesQuery();
  const [addState] = useAddStateMutation();
  const [updateState] = useUpdateStateMutation();

  useEffect(() => {
    reset(state);
  }, [state]);

  const onManageState: SubmitHandler<State> = async (data) => {
    try {
      if (state) {
        await updateState(data).unwrap();
      } else {
        await addState(data).unwrap();
        reset(initialValues);
      }
      setClose(false);
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManageState)}>
      <IonList>
        <IonItem className="ion-padding">
          <IonLabel>{state ? "Edit" : "Add"} State</IonLabel>
          <IonButton
            slot="end"
            className="ion-no-border"
            onClick={() => setClose(false)}
            color="danger"
          >
            <IonIcon
              slot="start"
              size="small"
              icon={closeCircleSharp}
            ></IonIcon>
            close
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Name<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Code<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Country<IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="countryName"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonSelect
                value={value}
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
              >
                {countries?.map((country) => {
                  return (
                    <IonSelectOption key={country.id} value={country.name}>
                      {country.name}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            )}
          />
        </IonItem>

        <IonButton expand="block" type="submit">
          {state ? "Update" : "Add"}
        </IonButton>
      </IonList>
    </form>
  );
};
export default ManageStates;
