import { emptySplitApi } from '.'
import { ServiceCategory } from '../types'

export const serviceCategoryApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceCategories: build.query<ServiceCategory[], void>({
      query: () => 'servicecategories',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ServiceCategory' as const, id })),
              { type: 'ServiceCategory', id: 'LIST' },
            ]
          : [{ type: 'ServiceCategory', id: 'LIST' }],
    }),
    addServiceCategory: build.mutation<ServiceCategory, Partial<ServiceCategory>>({
      query: (body) => ({
        url: `servicecategories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ServiceCategory', id: 'LIST' }],
    }),
    getServiceCategory: build.query<ServiceCategory, string>({
      query: (id) => `servicecategories/${id}`,
      providesTags: (result, error, id) => [{ type: 'ServiceCategory', id }],
    }),
    updateServiceCategory: build.mutation<void, Pick<ServiceCategory, 'id'> & Partial<ServiceCategory>>({
      query: ({ id, ...patch }) => ({
        url: `servicecategories/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ServiceCategory', id }],
    }),
    deleteServiceCategory: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `servicecategories/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'ServiceCategory', id }],
    }),
  }),
})

export const {
  useGetServiceCategoryQuery,
  useGetServiceCategoriesQuery,
  useAddServiceCategoryMutation,
  useUpdateServiceCategoryMutation,
  useDeleteServiceCategoryMutation,
} = serviceCategoryApi
