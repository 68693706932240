import { IonButton } from "@ionic/react";
import React from "react";
import { Agent } from "../../types";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory } from "react-router-dom";
import BaseRegisterContainer from "../BaseRegisterContainer";
import { baseRegistrationValidator } from "../../utils/validators";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddAgentMutation } from "../../services/admin";

const AgentRegisterContainer: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [registerAgent] = useAddAgentMutation();

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<Agent>({
    defaultValues: { countryName: 'India'},
    resolver: yupResolver(baseRegistrationValidator) });

  const onSubmit: SubmitHandler<Agent> = async (data) => {
    try {
      await registerAgent(data).unwrap();
      dispatch(displayAlert("Registration successful", "success"));
      history.push('/home');
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseRegisterContainer control={control} errors={errors} />
      <IonButton type="submit" expand="block">
        Register
      </IonButton>
    </form>
  );
};

export default AgentRegisterContainer;
