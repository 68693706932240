import { yupResolver } from "@hookform/resolvers/yup";
import { IonButton, IonDatetime, IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { addFreightForwardServiceRequestAsync } from "../../redux/serviceRequestSlice";
import { FreightForwardRequest } from "../../types";
import { freightForwardRequestValidator } from "../../utils/validators";
import FreightContainer from "./FreightContainer";

interface ContainerProps {}
const FreightRequestContainer: React.FC<ContainerProps> = () => {
  const minDate = moment().add(3, "days").format("YYYY-MM-DD");
  const maxDate = moment().add(6, "months").format("YYYY-MM-DD");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const provider = useAppSelector(state => state.auth.loginResponse);

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<FreightForwardRequest>({
    defaultValues: {
      freightForwardServiceDTO: {
        originCountryName: "India",
        destinationCountryName: "India",
      },
    },
    resolver: yupResolver(freightForwardRequestValidator),
  });

  const onSubmit: SubmitHandler<FreightForwardRequest> = async (data) => {
    const freightForwardRequest = {
      ...data,
      businessProviderCode: provider.profile.providerCode!,
      requestStatusName: "Awaiting Quote",
      quoteDueDate: moment(data.quoteDueDate).format(
        "YYYY-MM-DD"
      ),
      freightForwardServiceDTO: {
        ...data.freightForwardServiceDTO,
        // We need to only send date to the backend
        shipmentDate: moment(data.freightForwardServiceDTO.shipmentDate).format(
          "YYYY-MM-DD"
        ),
      },
    };
    try {
      unwrapResult(await dispatch(addFreightForwardServiceRequestAsync(freightForwardRequest)));
      dispatch(displayAlert("Successfully added a freight forward service request", "success"));
      history.goBack();
    } catch (err) {
      dispatch(displayAlert(err.message, 'danger'));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FreightContainer control={control} errors={errors}></FreightContainer>
      <IonItem>
        <IonLabel position="stacked" className="ion-text-wrap">
          Due Date for Quote<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="quoteDueDate"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonDatetime
              displayFormat="DD MMM YYYY"
              min={minDate}
              max={maxDate}
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              value={value}
            ></IonDatetime>
          )}
        />
        {errors.quoteDueDate && (
          <IonText color="danger">
            <small>{errors.quoteDueDate.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">
          Payment Terms (Days)<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="paymentTermDays"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonInput
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              value={value}
            />
          )}
        />
        {errors.paymentTermDays && (
          <IonText color="danger">
            <small>{errors.paymentTermDays.message}</small>
          </IonText>
        )}
      </IonItem>
      <IonButton expand="block" type="submit">
        Submit
      </IonButton>
    </form>
  );
};

export default FreightRequestContainer;
