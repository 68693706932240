import { emptySplitApi } from '.'
import { ServiceRegion } from '../types'

export const serviceRegionApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceRegions: build.query<ServiceRegion[], void>({
      query: () => 'serviceregions',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ServiceRegion' as const, id })),
              { type: 'ServiceRegion', id: 'LIST' },
            ]
          : [{ type: 'ServiceRegion', id: 'LIST' }],
    }),
    addServiceRegion: build.mutation<ServiceRegion, Partial<ServiceRegion>>({
      query: (body) => ({
        url: `serviceregions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ServiceRegion', id: 'LIST' }],
    }),
    getServiceRegion: build.query<ServiceRegion, string>({
      query: (id) => `serviceregions/${id}`,
      providesTags: (result, error, id) => [{ type: 'ServiceRegion', id }],
    }),
    updateServiceRegion: build.mutation<void, Pick<ServiceRegion, 'id'> & Partial<ServiceRegion>>({
      query: ({ id, ...patch }) => ({
        url: `serviceregions/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ServiceRegion', id }],
    }),
    deleteServiceRegion: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `serviceregions/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'ServiceRegion', id }],
    }),
  }),
})

export const {
  useGetServiceRegionQuery,
  useGetServiceRegionsQuery,
  useAddServiceRegionMutation,
  useUpdateServiceRegionMutation,
  useDeleteServiceRegionMutation,
} = serviceRegionApi
