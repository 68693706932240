import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

const AgentPostLogin: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Welcome Agent</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Welcome Agent</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonItem>
          <IonLabel>
            Welcome Agent!  We'll have things for you soon...
          </IonLabel>
        </IonItem>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default AgentPostLogin;
