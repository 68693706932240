import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
} from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import {
  useAddMeasurementMutation,
  useUpdateMeasurementMutation,
} from "../../services/measurements";
import { Measurement } from "../../types";
interface ContainerProps {
  setClose?: any;
  measurement?: Measurement;
}
const ManageMeasurements: React.FC<ContainerProps> = ({
  measurement,
  setClose,
}) => {
  const dispatch = useAppDispatch();
  const { control, reset, handleSubmit } = useForm<Measurement>();
  const [addMeasurement] = useAddMeasurementMutation();
  const [updateMeasurement] = useUpdateMeasurementMutation();

  const initialValues = {
    id: 0,
    name: "",
    description: "",
  };

  useEffect(() => {
    reset(measurement);
  }, [measurement]);

  const onManageMeasurement: SubmitHandler<Measurement> = async (data) => {
    try {
      if (measurement) {
        await updateMeasurement(data).unwrap();
      } else {
        await addMeasurement(data).unwrap();
        reset(initialValues);
      }
      setClose(false);
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  return (
    <form onSubmit={handleSubmit(onManageMeasurement)}>
      <IonList>
        <IonItem className="ion-padding">
          <IonLabel>{measurement ? "Edit" : "Add"} Measurement</IonLabel>
          <IonButton
            slot="end"
            className="ion-no-border"
            onClick={() => setClose(false)}
            color="danger"
          >
            <IonIcon
              slot="start"
              size="small"
              icon={closeCircleSharp}
            ></IonIcon>
            close
          </IonButton>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Name <IonText color="danger">*</IonText></IonLabel>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">Description</IonLabel>
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <IonInput
                onBlur={onBlur}
                onIonChange={(item) => onChange(item)}
                value={value}
              />
            )}
          />
        </IonItem>

        <IonButton expand="block" type="submit">
          {measurement ? "Update" : "Add"}
        </IonButton>
      </IonList>
    </form>
  );
};
export default ManageMeasurements;
