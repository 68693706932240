import {
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ServiceProvider } from "../../types";
import BaseRegisterContainer from "../BaseRegisterContainer";
import { useHistory } from "react-router";
import { serviceProviderRegistrationValidator } from "../../utils/validators";
import { useAppDispatch } from "../../hooks";
import { displayAlert } from "../../redux/alertSlice";
import { useAddServiceProviderMutation, useUpdateServiceProviderMutation } from "../../services/admin";
import { useGetMeasurementsQuery } from "../../services/measurements";
import { useGetServiceCategoriesQuery } from "../../services/serviceCategories";
import { useGetServiceRegionsQuery } from "../../services/serviceRegions";

interface ContainerProps {
  provider?: ServiceProvider,
  edit?: boolean
}

const FleetRegisterContainer: React.FC<ContainerProps> = ({ edit, provider }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [registerServiceProvider] = useAddServiceProviderMutation();
  const [updateServiceProvider] = useUpdateServiceProviderMutation();
  const {data: measurements, isLoading: isMeasurementLoading } = useGetMeasurementsQuery();
  const {data: serviceCategories, isLoading: isCategoryLoading} = useGetServiceCategoriesQuery();
  const {data: serviceRegions, isLoading: isRegionLoading} = useGetServiceRegionsQuery();

  useEffect(() => {
    reset(provider);
  }, [provider]);

  const {
    formState: { errors },
    control,
    reset,
    handleSubmit,
  } = useForm<ServiceProvider>({
    defaultValues: provider || { countryName: 'India'},
    resolver: yupResolver(serviceProviderRegistrationValidator),
  });

  const onSubmit: SubmitHandler<ServiceProvider> = async (data) => {
    try {
      if (edit) {
        const { referredByAgentCode, providerCode, email, panNumber, ...updatedData } = data;
        const res = await updateServiceProvider(updatedData).unwrap();
        const userDetails = JSON.parse(localStorage.user_details);
        const updatedUserDetails = { ...userDetails, profile: res };
        localStorage.setItem('user_details', JSON.stringify(updatedUserDetails));
      } else {
        await registerServiceProvider(data).unwrap();
      }
      dispatch(displayAlert(edit ? "Update successful" : "Register successful" , "success"));
      history.goBack();
    } catch (err) {
      const message = err.data.message ? JSON.stringify(err.data.message): JSON.stringify(err.data);
      dispatch(displayAlert(message, "danger"));
    }
  };

  if (isCategoryLoading || isMeasurementLoading || isRegionLoading) return <div>Loading...</div>

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseRegisterContainer control={control} errors={errors} edit={edit} />
      <IonItem>
        <IonLabel position="stacked">
          Service Category<IonText color="danger">*</IonText>
        </IonLabel>
        <Controller
          name="serviceCategoryNames"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonSelect
              value={value}
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              multiple={true}
            >
              {serviceCategories?.map((service) => {
                return (
                  <IonSelectOption key={service.id} value={service.name}>
                    {service.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
        />
        {errors.serviceCategoryNames ? (
          <IonText color="danger">
            <small>{(errors.serviceCategoryNames as any).message}</small>
          </IonText>
        ) : null}
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Service Region Names<IonText color="danger">*</IonText></IonLabel>
        <Controller
          name="serviceRegionNames"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <IonSelect
              value={value}
              onBlur={onBlur}
              onIonChange={(item) => onChange(item)}
              multiple={true}
            >
              {serviceRegions?.map((region) => {
                return (
                  <IonSelectOption key={region.id} value={region.name}>
                    {region.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
        />
        {errors.serviceRegionNames && (
          <IonText color="danger">
            <small>{(errors.serviceRegionNames as any).message}</small>
          </IonText>
        )}
      </IonItem>
      {edit && (
        <>
          {" "}
          <IonItem>
            <IonLabel position="stacked">Service Provider Code</IonLabel>
            <Controller
              name="providerCode"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  disabled={edit}
                  onIonChange={(item) => onChange(item)}
                  value={value}
                />
              )}
            />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Maximum Capacity Volume</IonLabel>
            <Controller
              name="maxCapacityVolume"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonInput
                  onBlur={onBlur}
                  onIonChange={(item) => onChange(item)}
                  value={value}
                />
              )}
            />
            {errors.maxCapacityVolume && (
              <IonText color="danger">
                <small>{errors.maxCapacityVolume.message}</small>
              </IonText>
            )}
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Measurement Name</IonLabel>
            <Controller
              name="measurementName"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <IonSelect
                  value={value}
                  onBlur={onBlur}
                  onIonChange={(item) => onChange(item)}
                >
                  {measurements?.map((measurement) => {
                    return (
                      <IonSelectOption
                        key={measurement.id}
                        value={measurement.name}
                      >
                        {measurement.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )}
            />
            {errors.measurementName && (
              <IonText color="danger">
                <small>{errors.measurementName.message}</small>
              </IonText>
            )}
          </IonItem>
        </>
      )}
      <IonButton type="submit" expand="block">
        {edit ? "Update" : "Register"}
      </IonButton>
    </form>
  );
};

export default FleetRegisterContainer;

