import axios from "axios";
import { API_ENDPOINT } from "../constants";
import { FreightForwardQuote, FreightForwardRequest, LoginForm, MultiModeQuote, MultiModeRequest,
  TransportQuote, TransportRequest } from "../types";

const config = {
  headers: {
    'Content-type': 'application/json'
  },
};

export const loginApi = async (loginForm: LoginForm) => {
  return await axios.post(`${API_ENDPOINT}/login`, loginForm, config);
}

export const getProfileApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/users/me`);
}

export const getLandTransportServiceRequestsApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/landtransportservicerequests`);
}

export const getFreightForwardServiceRequestsApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/freightforwardservicerequests`);
}

export const getMultiModeServiceRequestsApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/multimodeservicerequests`);
}

export const getLandTransportServiceQuotesApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/landtransportservicequotes`);
}

export const getFreightForwardServiceQuotesApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/freightforwardservicequotes`);
}

export const getMultiModeServiceQuotesApi = async () => {
  return await axios.get(`${API_ENDPOINT}/api/v1/multimodeservicequotes`);
}

export const addLandTransportServiceRequestApi = async (transportRequest: TransportRequest) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/landtransportservicerequests`, transportRequest, config);
}

export const addFreightForwardServiceRequestApi = async (freightForwardRequest: FreightForwardRequest) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/freightforwardservicerequests`, freightForwardRequest, config);
}

export const addMultiModeServiceRequestApi = async (multiModeRequest: MultiModeRequest) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/multimodeservicerequests`, multiModeRequest, config);
}

export const addLandTransportServiceQuoteApi = async (transportQuote: TransportQuote) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/landtransportservicequotes`, transportQuote, config);
}

export const addFreightForwardServiceQuoteApi = async (freightForwardQuote: FreightForwardQuote) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/freightforwardservicequotes`, freightForwardQuote, config);
}

export const addMultiModeServiceQuoteApi = async (multiModeQuote: MultiModeQuote) => {
  return await axios.post(`${API_ENDPOINT}/api/v1/multimodeservicequotes`, multiModeQuote, config);
}