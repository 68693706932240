import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../hooks';

interface PrivateRouteProps extends RouteProps {
  children: React.ReactNode
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const token = useAppSelector(state => state.auth.loginResponse.token);

  return (
    <Route
      {...rest}
      render={() =>
        token ? (
          children
        ) : (
          <Redirect exact
          to='/'
          />
        )
      }
    />
  );
};

export default PrivateRoute;