import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import ShipperRequirementsContainer from "../../components/shipper/ShipperRequirementsContainer";
import { useAppSelector } from "../../hooks";
import {
  RequirementType,
} from "../../types";

const ShipperRequirements: React.FC = () => {

  const provider = useAppSelector(state => state.auth.loginResponse);
  const transportRequests = useAppSelector(state => state.serviceRequest.transportRequests);
  const freightForwardRequests = useAppSelector(state => state.serviceRequest.freightForwardRequests);
  const multiModeRequests = useAppSelector(state => state.serviceRequest.multiModeRequests);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>Business Provider Requirements</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Business Provider Requirements</IonTitle>
          </IonToolbar>
        </IonHeader>
        {transportRequests.filter((item) =>
              item.businessProviderCode === provider.profile.providerCode
          ).length > 0 &&
          <IonItem>
            <IonLabel><h1>Transport Requests</h1></IonLabel>
          </IonItem>}
        {transportRequests
          .filter(
            (item) =>
              item.businessProviderCode === provider.profile.providerCode
          )
          .map((item) => (
            <ShipperRequirementsContainer
              key={item.id}
              requirementType={RequirementType.Transport}
              requirement={item}
            />
          ))}
        {freightForwardRequests.filter((item) =>
              item.businessProviderCode === provider.profile.providerCode
          ).length > 0 &&
          <IonItem>
            <IonLabel><h1>Freight Forward Requests</h1></IonLabel>
          </IonItem>}
        {freightForwardRequests
          .filter(
            (item) =>
              item.businessProviderCode === provider.profile.providerCode
          )
          .map((item) => (
            <ShipperRequirementsContainer
              key={item.id}
              requirementType={RequirementType.Freight}
              requirement={item}
            />
          ))}
        {multiModeRequests
          .filter(
            (item) =>
              item.businessProviderCode === provider.profile.providerCode
          ).length > 0 &&
          <IonItem>
            <IonLabel><h1>Multimode Requests</h1></IonLabel>
          </IonItem>}
        {multiModeRequests
          .filter(
            (item) =>
              item.businessProviderCode === provider.profile.providerCode
          )
          .map((item) => (
            <ShipperRequirementsContainer
              key={item.id}
              requirementType={RequirementType.MultiMode}
              requirement={item}
            />
          ))}
      </IonContent>
      <IonFooter className="ion-padding">
        <IonLabel className="ion-text-wrap">
          <IonText color="primary">
            &copy; 2021. RightFleet. All rights reserved
          </IonText>
        </IonLabel>
      </IonFooter>
    </IonPage>
  );
};

export default ShipperRequirements;
