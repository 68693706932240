import * as yup from "yup";

const serviceProviderMeasurementValidator = yup.object().shape(
  {
    maxCapacityVolume: yup.string().when("measurementName", {
      is: (val: string) => val && val.length > 0,
      then: yup.number().positive().required("Max capacity volume cannot be empty"),
    }),
    measurementName: yup.string().when("maxCapacityVolume", {
      is: (val: number) => val && val > 0,
      then: yup.string().required("Measurement name cannot be empty"),
      otherwise: yup.string().nullable()
    }),
  },
  [["maxCapacityVolume", "measurementName"]]
);

const businessProviderMeasurementValidator = yup.object().shape(
  {
    maxRequiredVolume: yup.number().when("measurementName", {
      is: (val: string) => val && val.length > 0,
      then: yup.number().positive().required("Max required volume cannot be empty"),
    }),
    measurementName: yup.string().when("maxRequiredVolume", {
      is: (val: number) => val && val > 0,
      then: yup.string().required("Measurement name cannot be empty"),
      otherwise: yup.string().nullable()
    }),
  },
  [["maxRequiredVolume", "measurementName"]]
);

export const transportValidator = yup.object().shape({
  originCountryName: yup
    .string()
    .required("Country is Mandatory"),
  originProvinceStateName: yup
    .string()
    .required("State is Mandatory"),
  originPlace: yup
    .string()
    .required("Place is Mandatory")
    .min(3, "Place should be at least 3 characters"),
  destinationCountryName: yup
    .string()
    .required("Country is Mandatory"),
  destinationProvinceStateName: yup
    .string()
    .required("State is Mandatory"),
  destinationPlace: yup
    .string()
    .required("Place is Mandatory")
    .min(3, "Place should be at least 3 characters"),
  commodityDescription: yup
    .string()
    .required("Description is Mandatory")
    .min(3, "Description should be at least 3 characters"),
  totalWeight: yup
    .number()
    .positive("Total weight has to be positive")
    .integer("No frations in total weight")
    .required("Total weight is Mandatory"),
  measurementName: yup
    .string()
    .required("Measurement is Mandatory"),
  cargoDimensionTypeName: yup
    .string()
    .required("Cargo Dimension Type is Mandatory"),
  shipmentDate: yup
    .string()
    .required("Quote Due Date is Mandatory"),
});

export const transportRequestValidator = yup.object().shape({
  landTransportServiceDTO: transportValidator,
  quoteDueDate: yup
    .string()
    .required("Quote Due Date is Mandatory"),
  paymentTermDays: yup
    .number()
    .required('Payment Term Days is Mandatory'),
});

export const freightFowardValidator = transportValidator.concat(
  yup.object().shape({
    cargoTypeName: yup.string().required("Cargo Type is Mandatory"),
  })
);

export const freightForwardRequestValidator = yup.object().shape({
  freightForwardServiceDTO: freightFowardValidator,
  quoteDueDate: yup
    .string()
    .required("Quote Due Date is Mandatory"),
  paymentTermDays: yup
    .number()
    .required('Payment Term Days is Mandatory'),
});

export const multiModeRequestValidator = yup.object().shape({
  landTransportServiceDTO: transportValidator,
  freightForwardServiceDTO: freightFowardValidator,
  quoteDueDate: yup
    .string()
    .required("Quote Due Date is Mandatory"),
  paymentTermDays: yup
    .number()
    .required('Payment Term Days is Mandatory'),
});

export const baseRegistrationValidator = yup.object().shape({
  companyName: yup
    .string()
    .required("Company Name is Mandatory")
    .min(3, "Given Company Name is Invalid"),
  contactPersonName: yup
    .string()
    .required("Contact Person Name is Mandatory for Registration")
    .min(3, "Given Contact Person Name is Invalid"),
  countryName: yup
    .string()
    .required("Country is Mandatory for Registration"),
  provinceStateName: yup
    .string()
    .required("State is Mandatory for Registration"),
  email: yup
    .string()
    .email("Given Email Id is Invalid")
    .required("Email is Mandatory for Registration"),
  postalCode: yup
    .string()
    .nullable()
    .max(6, "Invalid Postal code")
    .min(6, "Invalid Postal code"),
  mobileNumber: yup
    .string()
    .required("Mobile Number is Mandatory for Registration")
    .matches(/^[1-9][0-9]{9}$/, "Invalid mobile number"),
  gstNumber: yup
    .string()
    .nullable()
    .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, {
      messsage: "Invalid Gst Number",
      excludeEmptyString: true,
    })
    .notRequired(),
  websiteURL: yup.string().url("Given Website Url is Invalid"),
});

export const businessProviderRegistrationValidator = baseRegistrationValidator
  .concat(
    yup.object().shape({
      businessCategoryNames: yup
        .array()
        .required("Business Category should not be empty")
        .min(1, "Business Category should not be empty"),
    })
  )
  .concat(businessProviderMeasurementValidator);

export const loginValidator = yup.object().shape({
  username: yup
    .string()
    .required("Email is Mandatory for Login")
    .email("Given Email Id is Invalid"),
  password: yup.string().required("Password is Mandatory for Login"),
});

export const serviceProviderRegistrationValidator = baseRegistrationValidator
  .concat(
    yup.object().shape({
      serviceCategoryNames: yup
        .array()
        .required("Service Category should not be empty")
        .min(1, "Service Category should not be empty"),
      serviceRegionNames: yup
        .array()
        .required("Service Region cannot be empty")
        .min(1, "Service Region cannot be empty"),
    })
  )
  .concat(serviceProviderMeasurementValidator);
