import { IonCard, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { BusinessProvider } from "../../types";
import BaseProviderCardContainer from "./BaseProviderCardContainer";

interface ContainerProps {
  provider: BusinessProvider;
}
const BusinessProviderCardContainer: React.FC<ContainerProps> = ({
  provider,
}) => {
  return (
      <IonCard>
        <IonItem>
          <IonLabel>Provider Code</IonLabel>
          <IonLabel>{provider.providerCode}</IonLabel>
        </IonItem>
        <BaseProviderCardContainer provider={provider}/>
        <IonItem>
          <IonLabel>Business Category</IonLabel>
          <IonLabel>{provider.businessCategoryNames}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Measurement Type</IonLabel>
          <IonLabel>{provider.measurementName}</IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>Maximum Required Volume</IonLabel>
          <IonLabel>{provider.maxRequiredVolume}</IonLabel>
        </IonItem>
      </IonCard>
  );
};
export default BusinessProviderCardContainer;
