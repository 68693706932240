import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonLabel,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
  import React from "react";

  const NewRequirement: React.FC = () => {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="welcome" />
            </IonButtons>
            <IonTitle>New Requirement</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonHeader collapse="condense">
            <IonToolbar>
              <IonTitle size="large">New Requirement</IonTitle>
            </IonToolbar>
          </IonHeader>
        </IonContent>
        <IonFooter className="ion-padding">
          <IonToolbar>
            <IonLabel className="ion-text-wrap">
              <IonText color="primary">
                &copy; 2021. RightFleet. All rights reserved
              </IonText>
            </IonLabel>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  };

  export default NewRequirement;
