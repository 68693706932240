import { emptySplitApi } from '.'
import { BusinessCategory } from '../types'

export const businessCategoryApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getBusinessCategories: build.query<BusinessCategory[], void>({
      query: () => 'businesscategories',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'BusinessCategory' as const, id })),
              { type: 'BusinessCategory', id: 'LIST' },
            ]
          : [{ type: 'BusinessCategory', id: 'LIST' }],
    }),
    addBusinessCategory: build.mutation<BusinessCategory, Partial<BusinessCategory>>({
      query: (body) => ({
        url: `businesscategories`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BusinessCategory', id: 'LIST' }],
    }),
    getBusinessCategory: build.query<BusinessCategory, string>({
      query: (id) => `businesscategories/${id}`,
      providesTags: (result, error, id) => [{ type: 'BusinessCategory', id }],
    }),
    updateBusinessCategory: build.mutation<void, Pick<BusinessCategory, 'id'> & Partial<BusinessCategory>>({
      query: ({ id, ...patch }) => ({
        url: `businesscategories/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'BusinessCategory', id }],
    }),
    deleteBusinessCategory: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `businesscategories/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'BusinessCategory', id }],
    }),
  }),
})

export const {
  useGetBusinessCategoryQuery,
  useGetBusinessCategoriesQuery,
  useAddBusinessCategoryMutation,
  useUpdateBusinessCategoryMutation,
  useDeleteBusinessCategoryMutation,
} = businessCategoryApi
