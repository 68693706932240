import { yupResolver } from "@hookform/resolvers/yup";
import {
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
} from "@ionic/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LOGISTICS_SERVICES } from "../../constants";
import { TransportRequest } from "../../types";
import { transportRequestValidator } from "../../utils/validators";
import FreightRequestContainer from "./FreightRequestContainer";
import MultiModeRequestContainer from "./MultiModeRequestContainer";
import TransportRequestContainer from "./TransportRequestContainer";

interface ContainerProps {}

const ChooseLogisticsService: React.FC<ContainerProps> = () => {
  const [service, setService] = useState("");

  const {
    formState: { errors },
    control,
  } = useForm<TransportRequest>({
    defaultValues: { landTransportServiceDTO: { originCountryName: 'India', destinationCountryName: 'India'}},
    resolver: yupResolver(transportRequestValidator) });

  return (
    <>
      <IonItem>
        <IonLabel>Choose Logistics Service</IonLabel>
      </IonItem>

      <IonList className="ion-padding">
        <IonRadioGroup
          value={service}
          onIonChange={(e) => setService(e.detail.value)}
        >
          {LOGISTICS_SERVICES.map((item) => (
            <IonItem key={item} lines="none">
              <IonLabel className="ion-text-wrap">{item}</IonLabel>
              <IonRadio mode="md" slot="start" value={item} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonList>
      {service === "Transportation" && <TransportRequestContainer />}
      {service === "Freight forwarding" && <FreightRequestContainer />}
      {service === "Multimodel transportation" && <MultiModeRequestContainer />}
      <IonItem>
        <IonLabel>&nbsp;</IonLabel>
      </IonItem>
    </>
  );
};

export default ChooseLogisticsService;
