import { createAsyncThunk, createSlice, unwrapResult } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { TransportRequest, FreightForwardRequest, MultiModeRequest, TransportQuote, FreightForwardQuote,
  MultiModeQuote } from "../types";
import { addFreightForwardServiceQuoteApi, addFreightForwardServiceRequestApi, addLandTransportServiceQuoteApi,
  addLandTransportServiceRequestApi, addMultiModeServiceQuoteApi, addMultiModeServiceRequestApi,
  getFreightForwardServiceQuotesApi, getFreightForwardServiceRequestsApi, getLandTransportServiceQuotesApi,
  getLandTransportServiceRequestsApi, getMultiModeServiceQuotesApi, getMultiModeServiceRequestsApi } from "./rightfleetApi";

export interface ServiceRequestState {
  transportRequests: TransportRequest[];
  freightForwardRequests: FreightForwardRequest[];
  multiModeRequests: MultiModeRequest[];
  transportQuotes: TransportQuote[];
  freightForwardQuotes: FreightForwardQuote[];
  multiModeQuotes: MultiModeQuote[];
}

const initialState: ServiceRequestState = {
  transportRequests: [] as TransportRequest[],
  freightForwardRequests: [] as FreightForwardRequest[],
  multiModeRequests: [] as MultiModeRequest[],
  transportQuotes: [] as TransportQuote[],
  freightForwardQuotes: [] as FreightForwardQuote[],
  multiModeQuotes: [] as MultiModeQuote[],
};

export const getLandTransportServiceRequestsAsync = createAsyncThunk('serviceRequest/getTransport', async () => {
  const res = await getLandTransportServiceRequestsApi();
  return res.data.content;
});

export const getFreightForwardServiceRequestsAsync = createAsyncThunk('serviceRequest/getFreightForward', async () => {
  const res = await getFreightForwardServiceRequestsApi();
  return res.data.content;
});

export const getMultiModeServiceRequestsAsync = createAsyncThunk('serviceRequest/getMultiMode', async () => {
  const res = await getMultiModeServiceRequestsApi();
  return res.data.content;
});

export const getLandTransportServiceQuotesAsync = createAsyncThunk('serviceRequest/getTransportQuotes', async () => {
  const res = await getLandTransportServiceQuotesApi();
  return res.data.content;
});

export const getFreightForwardServiceQuotesAsync = createAsyncThunk('serviceRequest/getFreightForwardQuotes', async () => {
  const res = await getFreightForwardServiceQuotesApi();
  return res.data.content;
});

export const getMultiModeServiceQuotesAsync = createAsyncThunk('serviceRequest/getMultiModeQuotes', async () => {
  const res = await getMultiModeServiceQuotesApi();
  return res.data.content;
});

export const addLandTransportServiceRequestAsync = createAsyncThunk('serviceRequest/addTransport',
  async (transportRequest: TransportRequest) => {
  const res = await addLandTransportServiceRequestApi(transportRequest);
  return res.data;
});

export const addFreightForwardServiceRequestAsync = createAsyncThunk('serviceRequest/addFreightForward',
  async (freightForwardRequest: FreightForwardRequest) => {
  const res = await addFreightForwardServiceRequestApi(freightForwardRequest);
  return res.data;
});

export const addMultiModeServiceRequestAsync = createAsyncThunk('serviceRequest/addMultiMode',
  async (multiModeRequest: MultiModeRequest) => {
  const res = await addMultiModeServiceRequestApi(multiModeRequest);
  return res.data;
});

export const addLandTransportServiceQuoteAsync = createAsyncThunk('serviceRequest/addTransportQuote',
  async (transportQuote: TransportQuote) => {
  const res = await addLandTransportServiceQuoteApi(transportQuote);
  return res.data;
});

export const addFreightForwardServiceQuoteAsync = createAsyncThunk('serviceRequest/addFreightForwardQuote',
  async (freightForwardQuote: FreightForwardQuote) => {
  const res = await addFreightForwardServiceQuoteApi(freightForwardQuote);
  return res.data;
});

export const addMultiModeServiceQuoteAsync = createAsyncThunk('serviceRequest/addMultiModeQuote',
  async (multiModeQuote: MultiModeQuote) => {
  const res = await addMultiModeServiceQuoteApi(multiModeQuote);
  return res.data;
});

export const serviceRequestSlice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    filterTransport: (state, action) => {
      state.transportQuotes = state.transportQuotes.filter(item => item.serviceProviderCode === action.payload);
      state.transportRequests = state.transportRequests.map(item => state.transportQuotes.find(
        quote => quote.landTransportServiceRequestId === item.id) ? { ...item, quoteGiven: true} : item);
    },
    filterFreight: (state, action) =>{
      state.freightForwardQuotes = state.freightForwardQuotes.filter(item => item.serviceProviderCode === action.payload);
      state.freightForwardRequests = state.freightForwardRequests.map(item => state.freightForwardQuotes.find(
        quote => quote.freightForwardServiceRequestId === item.id) ? { ...item, quoteGiven: true} : item);
    },
    filterMultiMode: (state, action) =>{
      state.multiModeQuotes = state.multiModeQuotes.filter(item => item.serviceProviderCode === action.payload);
      state.multiModeRequests = state.multiModeRequests.map(item => state.multiModeQuotes.find(
        quote => quote.multiModeServiceRequestId === item.id) ? { ...item, quoteGiven: true} : item);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLandTransportServiceRequestsAsync.fulfilled, (state, action) => {
        state.transportRequests = action.payload
      })
      .addCase(getFreightForwardServiceRequestsAsync.fulfilled, (state, action) => {
        state.freightForwardRequests = action.payload
      })
      .addCase(getMultiModeServiceRequestsAsync.fulfilled, (state, action) => {
        state.multiModeRequests = action.payload
      })
      .addCase(getLandTransportServiceQuotesAsync.fulfilled, (state, action) => {
        state.transportQuotes = action.payload
      })
      .addCase(getFreightForwardServiceQuotesAsync.fulfilled, (state, action) => {
        state.freightForwardQuotes = action.payload
      })
      .addCase(getMultiModeServiceQuotesAsync.fulfilled, (state, action) => {
        state.multiModeQuotes = action.payload
      })
      .addCase(addLandTransportServiceRequestAsync.fulfilled, (state, action) => {
        state.transportRequests = [...state.transportRequests, action.payload]
      })
      .addCase(addFreightForwardServiceRequestAsync.fulfilled, (state, action) => {
        state.freightForwardRequests = [...state.freightForwardRequests, action.payload]
      })
      .addCase(addMultiModeServiceRequestAsync.fulfilled, (state, action) => {
        state.multiModeRequests = [...state.multiModeRequests, action.payload]
      })
      .addCase(addLandTransportServiceQuoteAsync.fulfilled, (state, action) => {
        state.transportQuotes = [...state.transportQuotes, action.payload];
        state.transportRequests = state.transportRequests.map((item) =>
        item.id === action.payload.landTransportServiceRequestId
          ? { ...item, quoteGiven: true } : item);
      })
      .addCase(addFreightForwardServiceQuoteAsync.fulfilled, (state, action) => {
        state.freightForwardQuotes = [...state.freightForwardQuotes, action.payload];
        state.freightForwardRequests = state.freightForwardRequests.map((item) =>
        item.id === action.payload.freightForwardServiceRequestId
          ? { ...item, quoteGiven: true } : item);
      })
      .addCase(addMultiModeServiceQuoteAsync.fulfilled, (state, action) => {
        state.multiModeQuotes = [...state.multiModeQuotes, action.payload];
        state.multiModeRequests = state.multiModeRequests.map((item) =>
        item.id === action.payload.multiModeServiceRequestId
          ? { ...item, quoteGiven: true } : item);
      })
    },
});

const { filterFreight, filterMultiMode, filterTransport } = serviceRequestSlice.actions;

export const fetchAllRequests = (): AppThunk => (dispatch) => {
  dispatch(getLandTransportServiceRequestsAsync());
  dispatch(getFreightForwardServiceRequestsAsync());
  dispatch(getMultiModeServiceRequestsAsync());
}

export const fetchAllRequestsQuotes = (providerCode: string): AppThunk => async (dispatch) => {
  unwrapResult(await dispatch(getLandTransportServiceRequestsAsync()));
  unwrapResult(await dispatch(getFreightForwardServiceRequestsAsync()));
  unwrapResult(await dispatch(getMultiModeServiceRequestsAsync()));
  unwrapResult(await dispatch(getLandTransportServiceQuotesAsync()));
  unwrapResult(await dispatch(getFreightForwardServiceQuotesAsync()));
  unwrapResult(await dispatch(getMultiModeServiceQuotesAsync()));
  dispatch(filterTransport(providerCode));
  dispatch(filterFreight(providerCode));
  dispatch(filterMultiMode(providerCode));
}

export default serviceRequestSlice.reducer;