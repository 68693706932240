import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_ENDPOINT } from "../constants";
import { RootState } from "../store";

export const emptySplitApi = createApi({
  reducerPath: 'splitApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${API_ENDPOINT}/api/v1/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.loginResponse.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },}),
  tagTypes: ['Measurement', 'BusinessCategory', 'ServiceCategory', 'Country', 'State',
    'Agent', 'BusinessProvider', 'ServiceProvider', 'ServiceRegion', 'CargoDimensionType',
    'CargoType', 'ServiceQuoteStatus', 'ServiceRequestStatus'],
  endpoints: () => ({}),
});
