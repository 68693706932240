import { emptySplitApi } from '.'
import { BusinessProvider, ServiceProvider, Agent } from '../types'

export const adminApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    getBusinessProviders: build.query<BusinessProvider[], void>({
      query: () => 'businessproviders',
      transformResponse: (response: any) => response.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'BusinessProvider' as const, id })),
              { type: 'BusinessProvider', id: 'LIST' },
            ]
          : [{ type: 'BusinessProvider', id: 'LIST' }],
    }),
    addBusinessProvider: build.mutation<BusinessProvider, Partial<BusinessProvider>>({
      query: (body) => ({
        url: `businessproviders/register`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'BusinessProvider', id: 'LIST' }],
    }),
    getBusinessProvider: build.query<BusinessProvider, string>({
      query: (id) => `businessproviders/${id}`,
      providesTags: (result, error, id) => [{ type: 'BusinessProvider', id }],
    }),
    updateBusinessProvider: build.mutation<BusinessProvider, Pick<BusinessProvider, 'id'> & Partial<BusinessProvider>>({
      query: ({ id, ...patch }) => ({
        url: `businessproviders/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'BusinessProvider', id }],
    }),
    deleteBusinessProvider: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `businessproviders/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'BusinessProvider', id }],
    }),
    getServiceProviders: build.query<ServiceProvider[], void>({
      query: () => 'serviceproviders',
      transformResponse: (response: any) => response.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ServiceProvider' as const, id })),
              { type: 'ServiceProvider', id: 'LIST' },
            ]
          : [{ type: 'ServiceProvider', id: 'LIST' }],
    }),
    addServiceProvider: build.mutation<ServiceProvider, Partial<ServiceProvider>>({
      query: (body) => ({
        url: `serviceproviders/register`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'ServiceProvider', id: 'LIST' }],
    }),
    getServiceProvider: build.query<ServiceProvider, string>({
      query: (id) => `serviceproviders/${id}`,
      providesTags: (result, error, id) => [{ type: 'ServiceProvider', id }],
    }),
    updateServiceProvider: build.mutation<ServiceProvider, Pick<ServiceProvider, 'id'> & Partial<ServiceProvider>>({
      query: ({ id, ...patch }) => ({
        url: `serviceproviders/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ServiceProvider', id }],
    }),
    deleteServiceProvider: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `serviceproviders/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'ServiceProvider', id }],
    }),
    getAgents: build.query<Agent[], void>({
      query: () => 'agents',
      transformResponse: (response: any) => response.content,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Agent' as const, id })),
              { type: 'Agent', id: 'LIST' },
            ]
          : [{ type: 'Agent', id: 'LIST' }],
    }),
    addAgent: build.mutation<Agent, Partial<Agent>>({
      query: (body) => ({
        url: `agents/register`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Agent', id: 'LIST' }],
    }),
    getAgent: build.query<Agent, string>({
      query: (id) => `agents/${id}`,
      providesTags: (result, error, id) => [{ type: 'Agent', id }],
    }),
    updateAgent: build.mutation<Agent, Pick<Agent, 'id'> & Partial<Agent>>({
      query: ({ id, ...patch }) => ({
        url: `agents/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Agent', id }],
    }),
    deleteAgent: build.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `agents/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, id) => [{ type: 'Agent', id }],
    }),
  }),
})

export const {
  useGetBusinessProviderQuery,
  useGetBusinessProvidersQuery,
  useAddBusinessProviderMutation,
  useUpdateBusinessProviderMutation,
  useDeleteBusinessProviderMutation,
  useGetServiceProviderQuery,
  useGetServiceProvidersQuery,
  useAddServiceProviderMutation,
  useUpdateServiceProviderMutation,
  useDeleteServiceProviderMutation,
  useGetAgentQuery,
  useGetAgentsQuery,
  useAddAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
} = adminApi
